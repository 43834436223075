import React, { Component, useContext, useState } from 'react'
import './App.css';
import logo from '../logo.webp'
import logoPNG from '../logonuevo.png'
import { Link, NavLink, Router } from 'react-router-dom'
import LoginModal from './LoginModal';
import {ServiceSelector} from './ServiceSelector';

class NavBar_NL_ND extends Component {




render(){
  // get current url
var currentUrl = window.location.href;
// if the string "blog"  is in the url, return true
let isBlog = currentUrl.includes("blog");
var inicio = <div className='w-100 mt-10-px mb-15-px'><ServiceSelector/></div>
var blog = <div className='w-100 mt-10-px mb-15-px'>
<NavLink className='azul-verdoso-#28BAC1 text-decoration-none w-100' to="/blog">Blog</NavLink>
</div>
if (isBlog){
  inicio = <div className='w-100 mt-10-px mb-15-px'>
  <NavLink className='azul-verdoso-#28BAC1 text-decoration-none w-100' to="/">Inicio</NavLink>
  </div>
  blog = 
  <div className='w-100 mt-10-px mb-15-px'>
  <NavLink className='azul-verdoso-#28BAC1 text-decoration-none w-100' to="/">Inicio</NavLink>
  </div>
  // <div className='w-100 mt-10-px mb-15-px'>
  //   <ServiceSelector/>
  // </div>
}


  return (

    <table className='w-100 h-80 '>
    <tr className='w-100 h-100'>
      <td className='w-90 h-100'>
        <table className='w-50 h-100'>
          <tr className='w-100 h-50'>
            <td className='w-50'>
            <div className='w-100 mt-10-px mb-15-px'>
            <ServiceSelector/>
          </div>
            </td>
            <td className='w-50'>
              <div className='w-100 mt-10-px mb-15-px'>
                <NavLink className='ml-20-px azul-verdoso-#28BAC1 text-decoration-none w-100' to="/register">Registro</NavLink>
              </div>
            </td>
          </tr>
          <tr className='w-100 h-50'>
            <td className='w-50'>
             {blog}
            </td>
            <td className='w-50'>
              <div className='w-100 mt-10-px mb-15-px'>
                <NavLink className='ml-20-px azul-verdoso-#28BAC1 text-decoration-none w-100 mb-15-px' to="/login">Entrar</NavLink>
              </div>
            </td>
          </tr>
        </table>
      </td>

      <td className='w-5 h-100'>
        
      </td>
    </tr>
  </table>



  //   <table className='w-100'>
  //   <tr className='w-80'>
  //     <td className='pt-pb-10-px'>
  //       <div className='btn-group'>
  //         <button type="button" className="btn btn-outline-primary" to="/register" onSubmit={(event) => {
  //                       event.preventDefault()
  //                   this.props.showNavigatorModalNavbar()}}><NavLink to="/register">Registrarse</NavLink>
  //         </button>

                  
                      
  //         <button type="button" className="btn btn-outline-primary" onSubmit={(event) => {
  //                 event.preventDefault()
  //                 this.props.showLoginModal()}}><NavLink  to="/login">Iniciar sesión</NavLink>
  //         </button>
                  
  //       </div>
  //     </td>
  //   </tr>
  // </table>


   )
}
  
}
export default NavBar_NL_ND;
