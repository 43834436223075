import React, { useState } from 'react'
import { Link, NavLink, Router } from 'react-router-dom'
import { Component } from 'react';
import './App.css';
import logo from '../logo.webp'
import logoPNG from '../logonuevo.png'
import ServiceSelector from './ServiceSelector';


class NavBarLogged_Desktop extends Component {
  render() {
  // console.log("NavBarLoggedDesktop")
  return (
    //si el usuario está logueado y tiene vista de escritorio
    <div className="d-flex justify-content-center float-end tx-al-mid h-100">
          <table className="navbar-default tx-al-mid h-100">
            <tbody className='tx-al-mid h-100'> 
              <tr className="w-50 mt-10-px tx-al-mid h-100">
              <td className='w-20 nav-btn rounded'>
                <div className='w-100 btn btn-sm btn-link tx-al-mid h-100'>
                {/* //btn btn-sm btn-block nav-btn*/}
                  <NavLink type="button" className="btn btn-sm btn-block nav-btn fs-5 w-100 me-sm-85 tx-al-mid h-100" to="/">Drive-Team</NavLink>
                </div>
              </td>
              <td className='w-20 nav-btn rounded mt-20-px'>
                <div className='w-50 btn btn-sm btn-link tx-al-mid h-100'>
                  <NavLink className="btn btn-sm btn-block nav-btn fs-5 w-100 me-sm-85  tx-al-mid h-100" to="/blog">Blog</NavLink>
                </div>
              </td>
              
                <td className='w-200 nav-btn rounded mt-20-px  tx-al-mid h-100'>
                  <div className="w-100 tx-al-mid h-100"> {/* Ajustamos el padding para más margen */}
                    <form
                      className="w-100 btn btn-sm btn-link"
                      onSubmit={(event) => {
                        event.preventDefault();
                        this.props.showNavigatorModalNavbar();
                      }}
                    >
                      <button type="submit" className="btn btn-sm btn-block nav-btn fs-5 w-100 me-sm-85 tx-al-mid h-100">
                        Más acciones
                      </button>
                    </form>
                  </div>
                </td>
                <td className='w-40 nav-btn rounded mt-20-px tx-al-mid h-100'>
              <ServiceSelector/>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
  )
      }
}



export default NavBarLogged_Desktop;