import React, {useState, useContext} from "react";
import ErrorMessage from "./ErrorMessage";
import { UserContext} from "../context/UserContext";
import CustomSpinner from "./CustomSpinner";
import { Link, NavLink } from 'react-router-dom';

//Componente que se encarga de la funcionalidad de "Iniciar Sesión"
const LoginModal = (props) => {
    
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [loader, setLoader] = useState(<div></div>);
    const [, setToken] = useContext(UserContext);
    // Se utiliza la librería "Cryto-js" para añadir salt al hash
         
    const CryptoJS = require("crypto-js");
    // variables que corresponden a Salt
    const salt = "farm1990M0O";
    const salt1 = "f1nd1ngn3m0";
    // se utilizó una variable "let" para poder reutilizarla dentro del código
    // Se añade salt a la contraseña y se cifra con SHA256 y se pasa a String
    let hashPassword = CryptoJS.SHA256(salt1+password+salt).toString();

    
      
    const submitLogin = async () => {

        //ENDPOINTS
//console.log("pasa por aqui")
        //let endpointlocal = "https://api.ecargo.link/api/token"
        let endpointlocal = "https://api.traxain.xyz/api/token"
        let endpoint = "https://api.traxain.xyz/api/token"
        let endpoint2 = "https://api.ecargo.link/api/token"
        //let endpoint2 = "http://localhost:8000/api/token"
        // let endpoint2 = "https://api.ecargo.link/api/token"

        
        
        let requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }, // ojo se cambio password a hashPasword
            body: JSON.stringify('grant_type=&username='+email+'&password='+hashPassword+'&scope=&client_id=&client_secret='),
            
        };
        
        let response
        let data
        setLoader(<CustomSpinner/>)

try {

    response = await fetch(endpointlocal, requestOptions);
    data = await response.json();

    if (!response.ok) {
        throw new Error('Request failed with status ' + response.status);
    }else{
        setLoader(<div></div>)
        // //console.log("Nos hemos autenticado con el endpointlocal")
    }

} catch (err) {
    // //console.log("fallo al autenticar endpointlocal");

    try {
        response = await fetch(endpoint, requestOptions);
        data = await response.json();

        if (!response.ok) {
            throw new Error('Request failed with status ' + response.status);
        }else{
            setLoader(<div></div>)
            // //console.log("Nos hemos autenticado con el endpoint")
        }

    } catch (err) {
        // //console.log("fallo al autenticar endpoint");
        try {
            response = await fetch(endpoint2, requestOptions);
            data = await response.json();
    
            if (!response.ok) {
                throw new Error('Request failed with status ' + response.status);
            }else{
                setLoader(<div></div>)
                // //console.log("Nos hemos autenticado con el endpoint2")
            }
    
        } catch (err) {
            // //console.log("fallo al autenticar endpoint2");
            response= "error"
        }
    }
}

    //Si los datos introducidos existen y son correctos, carga la página del usuario
    //en caso de no ser correcto, se vuelve a mirar la petición con el antiguo sistema de encriptación

    if(!response.ok){
        // se vuelve a llamar al RequestOptions, pero con el antiguo sistema
        requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }, 
            
            body: JSON.stringify('grant_type=&username='+email+'&password='+password+'&scope=&client_id=&client_secret='),
        };
    
        try{
            response = await fetch(endpointlocal, requestOptions);

            data = await response.json();
            if (!response.ok) {
                throw new Error('Request failed with status ' + response.status);
            }else{
                setLoader(<div></div>)
                // //console.log("Nos hemos autenticado con el endpointlocal metodo antiguo")
            }
            
        } catch (err){
            // //console.log("fallo al autenticar endpointlocal metodo antiguo")
            try{
            response = await fetch(endpoint, requestOptions);

            data = await response.json();
            if (!response.ok) {
                throw new Error('Request failed with status ' + response.status);
            }else{
                setLoader(<div></div>)
                // //console.log("Nos hemos autenticado con el endpoint metodo antiguo")
            }


            }catch(err){
                // //console.log("fallo al autenticar endpoint metodo antiguo")
                try{
                    response = await fetch(endpoint2, requestOptions);

                data = await response.json();
                    if (!response.ok) {
                        throw new Error('Request failed with status ' + response.status);
                    }else{
                        setLoader(<div></div>)
                        // //console.log("Nos hemos autenticado con el endpoint2")
                    }
                    
                } catch (err){
                    // //console.log("fallo al autenticar endpoint2 metodo antiguo")
                    if (response && response.status === 401) {
                        // Credenciales incorrectas
                        // //console.log("Ponemos el mensaje de credenciales incorrectas");
                        setLoader(<div></div>)
                        setErrorMessage("Usuario o contraseña incorrectos");
                        return;
                    } else {
                        // Otro tipo de error
                        // //console.log("Otro tipo de error");
                        console.error('Error:', err);
                    }
                }
            }
        }
                        
    }


        // //console.log("Salimos de las comprobaciones de login")


            let estadoUsuario = localStorage.getItem("TraxainUserToken");
            setToken(data.access_token);
            comprobarEstado();

            function comprobarEstado() {
                if (estadoUsuario !== "null") {
                    //console.log("busca el localhost");
                    // Redirige a la ruta de inicio antes de recargar la página
                    if (Math.random()>0.5){
                        setTimeout(() => {
                          window.location.href = 'https://drive-team.es';
                        }, 2)
                      }else{
                        setTimeout(() => {
                          window.location.href = 'https://www.drive-team.es';
                        }, 2)
                
                      }
                } else {
                    estadoUsuario = localStorage.getItem("TraxainUserToken");
                    setTimeout(() => comprobarEstado(), 50);
                }
            }
            
        

    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        //console.log("pasa por aqui");
        await submitLogin();
        
    };
   
    var show = true
    var showHideClassName = show ? "modal display-block" : "modal display-none";

    //Aspecto visual del box de inicio de sesión
    return (
        <div className="showHideClassName">
            <section className="modal-main text-center b-1-s b-r-10" style={{  backgroundColor: '#e6e6fa' }}>
                <div className="column">
                    <form className="box" onSubmit={handleSubmit}>
                        <h1 className="title has-text-centered">Inicia sesión</h1>
                        <div className="field">
                            <label className="label text-center">Email</label>
                            <div className="control">
                                <input type="email" placeholder="Email" value={email} onChange={ (e) => setEmail(e.target.value)} className="input" required/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label text-center">Contraseña</label>
                            <div className="control">
                                <input type="password" placeholder="Enter Password" value={password} onChange={ (e) => setPassword(e.target.value)} className="input" required/>
                            </div>
                        </div>
                        <br/>
                        <ErrorMessage message={errorMessage}/>
                        <br/>
                        {loader}
                        <br/>
                        <div className="text-center">
                        <button className="button is-primary" style={{backgroundColor:'#0d6efd', borderColor:'#0d6efd'}} type="submit">
                            Entrar
                        </button>
                        </div>
                       
                    </form>
                </div>
                <NavLink type="button" className="btn w-50 mb-5-px border-bottom-1-px bg-white" to="/">
                            Volver atrás
                    </NavLink>

            </section>
        </div>

    ); 
};

export default LoginModal;