import React, { useState, useEffect } from "react";
import { FacebookShareButton, TwitterShareButton, FacebookIcon, XIcon } from "react-share";
import RoundEnumeratedList from "./RoundEnumeratedList";
const content = null;

const paragraphs = ["Un dashboard para analizar la productividad de cada empleado.",
"Previsión y cálculo automático de dietas.","Previsión y cálculo automático de Complementos por desplazamiento.<",
"Detección de actividades anómalas en el tacógrafo y aclaración por SMS.",
"Cálculo y alertas de cumplimiento de jornadas."]


const paragraphs2 = ["El horizonte I, para las emisiones directas de la empresa, es decir el combustible que se quema por tus propios equipos e instalaciones.", 
"El horizante II, que es lo que se emitió para producir la energía que se consume en nuestra empresa", 
"Y por último, el horizonte III, que implica todas las emisiones que han hecho nuestros proveedores y que harán nuestros clientes. Este horizonte III es  el más difícil de calcular."]

const blogListTotal = [
  
  {
    id: 2,
    title: "¿Qué pasa con el CMR digital?",
    summary:
      "Se está debatiendo en el Congreso una medida en el proyecto de ley de Movilidad Sostenible que establece la obligatoriedad de utilizar documentos de control digitales para el transporte, incluyendo el transporte internacional, a partir del 1 de octubre de 2024. Esta medida puede representar un avance en términos de transparencia y control en el sector del transporte.",
    path: "cmr-digital-actualidad",
    author: "Ignacio Pardo",
    date: "11 de Marzo de 2024",
    imageUrl: "/cmr.webp",
    ContentComponent: (content) => (
      <div className="text-align-justify">
        <p className="text-intend-30p">
          Hoy queremos compartir con vosotros una información muy relevante para el sector del transporte. Se trata de una medida que se está
          debatiendo en el Congreso de los Diputados y que puede tener un impacto significativo en la forma en que se lleva a cabo el transporte de
          mercancías y viajeros.
        </p>
        <br></br>
        <br></br>
        <h2 className="fw-bold">¿Por qué tanto revuelo?</h2>
        <br></br>
        <p className="text-intend-30p">
          En el proyecto de ley de Movilidad Sostenible se ha establecido la obligatoriedad de que el documento de control exigido para el transporte,
          con algunas excepciones, sea necesariamente digital a partir del 1 de octubre de 2024. Esto significa que se deberá utilizar una plataforma
          autorizada por la Dirección General de Transporte Terrestre y que el documento digital deberá contener los datos requeridos para su
          inspección.
        </p>
        <br></br>
        <br></br>
        <h2 className="fw-bold">¿Se aplicará también al internacional?</h2>
        <br></br>
        <p className="text-intend-30p">
          <b>SI.</b> Es importante destacar que esta medida se aplicará también al transporte internacional y que se permitirán las cartas de porte
          establecidas por las normativas nacionales o extranjeras que contengan los datos requeridos. Sin embargo, se interpreta que una carta de
          porte CMR en papel sería válida como documento contractual entre las partes, pero no como documento de control a efectos administrativos.
          Por lo tanto, como decimos, el documento electrónico, tanto CMR como Carta de Porte, será <b>OBLIGATORIO.</b>
        </p>
        <br></br>
        <br></br>
        <h2 className="fw-bold">En resumen</h2>
        <br></br>
        <p className="text-intend-30p">
          En conclusión, esta medida puede representar un gran avance en términos de transparencia y control en el sector del transporte, y puede
          contribuir a la promoción de prácticas más sostenibles y eficientes en este ámbito. Como siempre, estaremos atentos a las novedades y
          actualizaciones en este sentido, para mantenerles informados de todo lo que pueda ser relevante para el sector.
        </p>
        <br></br>
      </div>
    ),
  },
  {
    id: 3,
    title: "Crecimiento en las Matriculaciones de Vehículos Comerciales en Noviembre",
    summary:
      "Noviembre ha registrado un notable aumento en las matriculaciones de camiones y furgonetas en España, reflejando la fortaleza del mercado de vehículos industriales y comerciales.",
    path: "matriculacion-automocion-mercado",
    author: "Ignacio Pardo",
    date: "18 de Marzo de 2024",
    imageUrl: "/trucksline.webp",
    ContentComponent: (content) => (
      <div className="text-align-justify">
        <p className="text-intend-30p">
          El mes de noviembre ha sido testigo de un incremento significativo en las matriculaciones de vehículos comerciales. Los camiones han
          experimentado un crecimiento del 17,2%, alcanzando las 2.961 unidades vendidas, mientras que el acumulado del año muestra un aumento del
          22,5% con un total de 26.335 unidades.
        </p>
        <br></br>
        <p className="text-intend-30p">
          Por otro lado, las furgonetas han visto un impresionante crecimiento del 42,4% en noviembre, con 14.474 unidades vendidas. En total, hasta
          noviembre, se han comercializado 132.921 vehículos, lo que representa un aumento del 23,7%.
        </p>
        <br></br>
        <p className="text-intend-30p">
          Este crecimiento ha sido notable en todos los canales, destacando especialmente en el canal de empresas, que ha registrado un aumento del
          44,8%. Las ventas a autónomos también han crecido significativamente, con un aumento del 32,2%.
        </p>
        <br></br>
      </div>
    ),
  },
  {
    id: 4,
    title: "Palibex y la Inteligencia Artificial: Innovación en la Logística",
    summary:
      "Palibex está integrando la inteligencia artificial en sus operaciones logísticas, mejorando la eficiencia en la gestión de mercancías y la coordinación entre centros. Exploramos cómo esta tecnología está transformando sus operativas diarias.",
    path: "palibex-ia-logistica",
    author: "Ignacio Pardo",
    date: "25 de Marzo de 2024",
    imageUrl: "/palibex.webp",
    ContentComponent: (content) => (
      <div className="text-align-justify">
        <p className="text-intend-30p">
          Palibex está aprovechando las ventajas que aporta la inteligencia artificial en el núcleo de sus operaciones, especialmente en la gestión y
          coordinación del movimiento de las mercancías.
        </p>
        <br></br>
        <p className="text-intend-30p">
          El director de Sistemas, Marcos Fierro, resalta la mejora en el dimensionamiento de palés y camiones mediante IA, lo que optimiza el TMS y
          ayuda en la previsión de necesidades.
        </p>
        <br></br>
        <p className="text-intend-30p">
          La IA juega un papel crucial en la mejora operativa de Palibex, analizando datos para la toma de decisiones y optimización de procesos como
          transporte y mantenimiento.
        </p>
        <br></br>
        <p className="text-intend-30p">
          Las innovaciones como la navegación por realidad aumentada de Google Maps y Microsoft Copilot están siendo estudiadas para su integración en
          las tareas diarias de la empresa.
        </p>
        <br></br>
        <p className="text-intend-30p">
          Fierro subraya la importancia de adaptar innovaciones tecnológicas para convertirlas en mejoras tangibles y mantener la competitividad
          empresarial.
        </p>
        <br></br>
      </div>
    ),
  },
  {
    id: 5,
    title: "Portugal sigue el ejemplo de España con el céntimo sanitario",
    summary:
      "El Tribunal europeo ha fallado en contra de la “contribución por el servicio vial” en Portugal, similar al “céntimo sanitario” en España, afectando a los impuestos sobre combustibles y abriendo la puerta a reclamaciones de reembolso.",
    path: "portugal-sentencia-impuestos",
    author: "Ignacio Pardo",
    date: "1 de Abril de 2024",
    imageUrl: "/portugal.webp",
    ContentComponent: (content) => (
      <div className="text-align-justify">
        <p className="text-intend-30p">
          Al igual que en España con el "céntimo sanitario", el Alto Tribunal europeo ha fallado en contra de la "contribución por el servicio vial"
          en Portugal. Este impuesto, creado en 2007, se aplicaba a combustibles para financiar el mantenimiento vial, recaudando más de 600 millones
          de euros anuales.
        </p>
        <br></br>
        <p className="text-intend-30p">
          El impuesto incluía un recargo en diésel, gasolina y gas licuado de petróleo. En España, el céntimo sanitario fue declarado ilegal por el
          Tribunal de Justicia de la Unión Europea, llevando a su derogación y a la apertura de reclamaciones de reembolso.
        </p>
        <br></br>
        <p className="text-intend-30p">
          Ante un fallo similar, Portugal derogó su contribución en diciembre de 2022, incorporándola al impuesto sobre hidrocarburos. Ahora, los
          transportistas y otros consumidores afectados pueden reclamar las cantidades pagadas en los últimos cuatro años.
        </p>
        <br></br>
        <p className="text-intend-30p">
          Empresas de transporte en Portugal pueden iniciar procedimientos administrativos para solicitar reembolsos de la contribución declarada
          ilegal por la justicia europea.
        </p>
        <br></br>
      </div>
    ),
  },
  {
    id: 6,
    title: "No Dejes Que Tus Horas Extras Se Evaporen: Domina el Uso del Tacógrafo Digital",
    summary:
      "Descubre en este artículo cómo tu fiel compañero en la ruta, el tacógrafo digital, puede ser la clave para no perder ni un euro de tus merecidas horas extras. Aprende a manejar este dispositivo con precisión y conviértelo en tu aliado para asegurar cada hora de esfuerzo trabajada. No permitas que tus ganancias adicionales se desvanezcan por un mal registro; toma el volante de tu carrera profesional con responsabilidad y conocimiento.",
    path: "tacografo-logistica-horas-extras",
    author: "Ignacio Pardo",
    date: "8 de Abril de 2024",
    imageUrl: "/tacografo.webp",
    ContentComponent: (content) => (
      <div className="text-align-justify">
        <p className="text-intend-30p">
          Imagínate navegando por un mar de carreteras con tu camión, donde el tacógrafo digital es tu brújula. Este aparato no solo te guía, sino que
          también cuenta la historia de tu jornada laboral. Pero, ¿sabías que cómo lo usas puede hacer la diferencia entre ganar más o perder dinero
          en tus horas extras? Vamos a desentrañar este misterio y recuerda, para entender mejor tu tacógrafo, visita drive-team.es.
        </p>
        <br></br>
        <br></br>
        <h2 className="fw-bold">1. El Tacógrafo Digital: Tu Mejor Aliado en la Ruta</h2>
        <br></br>
        <p className="text-intend-30p">
          El tacógrafo digital es como un diario fiel de tu vida en la carretera. Registrar correctamente tus horas de conducción y descanso es tan
          crucial como tener un buen mapa en un viaje desconocido. Usarlo adecuadamente es sinónimo de seguir el camino de la seguridad y la
          legalidad. Piénsalo como el contador de tu esfuerzo, que debe reflejar cada minuto trabajado con precisión de relojero.
        </p>
        <br></br>
        <br></br>
        <h2 className="fw-bold">2. Cuidado con Perder Dinero: El Uso Incorrecto del Tacógrafo y las Consecuencias en Horas Extras</h2>
        <br></br>
        <p className="text-intend-30p">
          Un tacógrafo mal utilizado es como tener un agujero en el bolsillo de tu pantalón favorito; poco a poco, tus ganancias en horas extras se
          pueden esfumar sin que te des cuenta. Si marcas descanso cuando en realidad estás trabajando, es como decirle a tu cartera que no quiere más
          dinero. Las horas extras son como un premio por tu duro trabajo, y perderlas por un mal registro es como dejar que ese premio se escape de
          tus manos. Así que, usar este aparato con honestidad y precisión es el truco para mantener tu billetera feliz.
        </p>
        <br></br>
        <br></br>
        <h2 className="fw-bold">3. Responsabilidad al Volante: Cumplir con la Ley para un Futuro Seguro</h2>
        <br></br>
        <p className="text-intend-30p">
          Usar correctamente el tacógrafo no es solo una regla, es como el cinturón de seguridad para tu carrera profesional. Cada registro preciso es
          un paso hacia un futuro más seguro y estable. Imagínate que cada entrada correcta en tu tacógrafo es como construir un puente sólido sobre
          un río de incertidumbre legal. Cumplir con la normativa no solo te protege de multas, sino que también mantiene intacta tu reputación como
          conductor responsable.
        </p>
        <br></br>
        <b>Conclusión</b>
        <br></br>
        <p className="text-intend-30p">
          El tacógrafo digital, ese pequeño aparato en tu tablero, es un gigante en importancia. Usarlo correctamente no solo es seguir un mapa hacia
          la seguridad y la legalidad, sino que también es asegurar que cada hora de esfuerzo se refleje en tu salario. Así que, la próxima vez que
          subas a tu camión, recuerda: el tacógrafo es tu aliado, tu contador honesto y tu guardián en la carretera. Y para entenderlo aún mejor, no
          olvides visitar drive-team.es. ¡Que tus rutas sean siempre seguras y rentables!
        </p>
        <br></br>
      </div>
    ),
  },
  {
    id: 7,
    title: "II Foro Internacional Mujer y Sector de Transporte",
    summary:
      "El evento destacó la importancia de la visibilidad y el empoderamiento de las mujeres en el sector del transporte, abordando desafíos y comprometiéndose a continuar el apoyo.",
    path: "mujer-igualdad-logistica",
    author: "Ignacio Pardo",
    date: "15 de Abril de 2024",
    imageUrl: "/foromujer.webp",
    ContentComponent: (content) => (
      <div className="text-align-justify">
        <p className="text-intend-30p">
          Celebrado en Valencia el 22 de febrero, el <strong>II Foro Internacional Mujer y Sector de Transporte</strong>, organizado por Andamur, puso
          de relieve la participación y los retos de las mujeres en el ámbito del transporte. Con un enfoque en aumentar la visibilidad femenina en un
          sector predominantemente masculino, el evento resaltó que solo el 4% de los empleos de conducción son ocupados por mujeres, subrayando la
          necesidad de abordar esta desigualdad.
        </p>
        <br></br>
        <p className="text-intend-30p">
          A través de mesas redondas y testimonios personales, las participantes exploraron los avances hacia la igualdad de género, aunque
          enfatizaron en las persistentes barreras para la conciliación entre el trabajo y la vida personal. El foro cerró con el anuncio de la
          próxima edición y la introducción de premios dedicados a las contribuciones femeninas en el sector, reafirmando el compromiso con el
          empoderamiento de las mujeres en el transporte.
        </p>
        <br></br>
      </div>
    ),
  },
  {
    id: 9,
    title: "Servicio de Cálculo de Emisiones de CO2 para Camiones de Traxain",
    summary:
      "Calcula las emisiones de CO2 de tus camiones con el tacógrafo siguiendo la CSRD.",
    path: "calculo-emisiones-co2-transporte-camiones",
    author: "Ignacio Pardo",
    date: "19 de Diciembre de 2024",
    imageUrl: "/camion-natural.webp",
    ContentComponent: (content) => (
      <div className="text-align-justify">
        <p className="text-intend-30p">
          Tras la entrada en vigor de la Directiva de la UE sobre Informes de Sostenibilidad Corporativa (CSRD) las empresas de más de 250 trabajadores,  o que facturen más de 40 millones de euros tendrán que reportar el dióxido de carbono emitido  en sus actividades.
        </p>
        <br></br>
        <br></br>
        <h2 className="fw-bold">
          ¿Cómo afecta esto a las empresas de transporte?
        </h2>
        <br></br>
        <p className="text-intend-30p">
          Las empresas de transporte, naturalmente, también tendrán que cumplir con la CSRD. Sin  empargo esto puede plantear muchas preguntas, ¿cómo lo calculamos? ¿Qué factores tenemos que tener en cuenta?
        </p>
        <br></br>
        <p className="text-intend-30p">
          Si buscas la CSRD en internet, encontrarás que te hablan de los tres horizontes de emisiones:
          <RoundEnumeratedList items={paragraphs2}/> </p>
        <br></br>
        <p className="text-intend-30p">
          Afortunadamente, en 2025 la CSRD sólo los obliga a reportar el alcance I y II de 2024 a las grandes empresas. Será en 2026 cuando estas empresas tengan que reportar las emisiones del alcance III de 2025, mientras que la mayoría de empresas solo tendrán que hacerlo en 2027.
        </p>
        <br></br>
        <p className="text-intend-30p">
          Por tanto, este artículo se centra en las emisiones del alcance I, que en definitiva es el que más afecta a las empresas de transporte de mercancías. La metodología a seguir para calcular las emisiones en cada modo de transporte, los factores a tener en cuenta, y la forma de hacer el reporte está detallada en la ISO 14083:2023, que tambien incluye el tratamiento de ciertas situaciones especiales dependiendo del tipo de operación.
        </p>
        <br></br>
        <br></br>
        <h2 className="fw-bold">
          ¿Tengo que estudiarme el ISO 14083 para poder hacer el reporte de mis emisiones?
        </h2>
        <br></br>
        <p className="text-intend-30p">
          No es necesario, puedes usar sistemas como el Software de Cálculo de Emisiones de CO2 para Camiones de Traxain, que te ayudarán a calcular las emisiones de CO2 de tus camiones con el tacógrafo siguiendo la CSRD. También puedes contar con nosotros para diseñar un sistema a tu medida. Si no quieres hacer ninguna de las dos cosas, en nuestra página encontrarás explicaciones detalladas sobre la ISO 14083.
        </p>
        <br></br>
        <br></br>
        <h2 className="fw-bold">
        ¿Cómo funciona el de Cálculo de Emisiones de CO2 para Camiones de Traxain?        
        </h2>
        <br></br>
        <p className="text-intend-30p">
        </p>
        <br></br>
        <p className="text-intend-30p">
        Paralelamente, en función del número de bastidor incluido en el tracógrafo, identificamos el modelo del vehículo y su año de matriculación, con lo que obtenemos las emisiones de CO2 calculadas en por la Agencia Europea del Medio Ambiente específicamente para sus circunstancias. Por ejemplo, en fucnión de la actividad del tacógrafo <a href="https://www.drive-team.es">Drive-Team.es</a> detecta si el camión se dedica a delivery urbano, transporte regional, lacional o internacional, lo cual infrluye significamente en sus emisiones.
        </p>
        <br></br>
        <br></br>
        <h2 className="fw-bold">
          ¿Qué es la densidad de emisiones y cómo calcularla?
        </h2>
        <br></br>
        <p className="text-intend-30p">
          Una de las especificaciones de la ISO 14083 es que no nos solicita simplemente el nivel total de emisiones producidas por nuestra empresa, sino que nos pide que lo pongamos en relación con una unidad  de medida concreta, que en el caso del transporte por carretera generalemnte es la masa total transportada en toneladas métricas (1000 kg) por  kilómetro, alternativamente se pueden usar otras unidades estándar como el twenty-feet-equivalent-unit (TEU). Esto tiene su sentido ya que, además de saber cuanto estamos emitiendo en un periodo concreto, queremos saber cómo se compara eso con periodos anteriories o posteriores, y como el tonelaje total o la distancia recorrida varían en función de multiples factores, se hace necesario tener una métrica con la que comparar situaciones diferentes.
        </p>
        <br></br>
       
        <p className="text-intend-30p">
        Por tanto, es necesario considerar la masa transportada, para lo cual considera por defecto una carga correspondiente al 50% de su carga máxima. Para obtener un cálculo más exacto, te recoemndamos que empieces a usar nuestro sistema de CMRs electrónicos. Si todavía no estás convencido, también puedes indicar la masa de la carga conectándote a <a href="https://www.drive-team.es">drive-team.es</a> (nuestra plataforma está hecha para conectarse fácilmente con otros sistemas) si no quieres conectar nada, tambien se puede indicar la masa de la carga manualmente, importanto un excel, o si ya usas los otros servicios de Traxain.
       
       </p>
       <br></br>
       
       <p className="text-intend-30p">
       Por otro lado, si desconoces la masa de la carga, lo cual es frecuente en ciertos sectores, pero conoces el consumo de combustible durante ese periodo, <a href="https://www.drive-team.es">Drive-Team.es</a> puede calcular cuanto se estaba tranporando calculando la diferencia entre el consumo en vacío y el consumo efectivo
      
      </p>
      <br></br>
        <p className="text-intend-30p">
      Una vez introducidos los tacógrafos de los vehículos, tendrás como resultado el total de las emisiones producidas por tu empresa en un periodo concreto, con el que ya podrás hacer tu informe de empresa. Pero...
        </p>
      <br></br>
        <br></br>
        <h2 className="fw-bold">
      ¿Qué pasa si tengo que hacer un desglose por cliente?
        </h2>

        <br></br>
      
        <p className="text-intend-30p">
      El desglose por cliente es uno de los temas más delicados, aunque no es estrictamente necesario a la hora de presentar nuestro reporte si somos empresas obligadas a ello, sí es posible que nuestros clientes nos lo pidan para incluirlo en sus cálculos de su propio alcance III.
        </p>
        <br></br>
        <p className="text-intend-30p">
          Una de las preguntas más comunes es cómo debemos contabilizar los viajes en vacío. La ISO 14083 especifica que el viaje en vacío se debe contabilizar como correspondiente al cliente para el cual se ha realizado este desplazamiento. Esto implica el movimiento desde la base al punto de carga y el movimiento desde el punto de descarga a la base. 
       </p>
        <br></br>
        <p className="text-intend-30p">
          Esto puede sonar sencillo a primera vista, pero los lectores que conozcan el sector de transporte con camiones no tardarán en darse cuenta de que no todos los servicios empiezan y terminan en nuestra base, sino que pueden conctaetarse unos tras otros, en muchos casos incluso se realiza grupage o carga fraccionada, lo que significa que se llevan cargas de distintos clientes en un mismo camión. En estos casos, según la ISO 14083, el viaje en vacío se debe contabilizar en la base de origen, y no en la base de destino.
          </p>
        <br></br>
        <p className="text-intend-30p">
          Empecemos por las cargas fraccionadas o grupaje, en este caso, tenemos que imputar a cada cliente una cantidad de CO2 prorrateada según la masa transportada para cada cliente respecto a la carga transportada total durante el recorrido en cuestión.
          </p>
        <br></br>
        <p className="text-intend-30p">
          En cuanto a los viajes que se realizar para desplazarse desde la base al punto de carga, y desde descarga hasta la base, estas emisiones  se imputan al cliente para el que se ha realizado el viaje. 
          </p>
        <br></br>
        <p className="text-intend-30p">
          De modo similar al tema de la masa de carga, el origen y destino de cada servicio y el cliente al que se le ha realizado el viaje, así como los movimientos en vacío para realizar cada viaje, no es información que se encuante directamente en el tacógrafo. Para obtener estos datos y que <a href="https://www.drive-team.es">Drive-Team.es</a> pueda imputar las emisiones de CO2 a cada uno de tus clientes, te recomendamos de nuevo que aproveches a usar nuestro servicio de CMR electrónico, con lo que resolverás dos problemas a la vez.
          </p>
          <br></br>
          <p className="text-intend-30p">Si por cualquier motivo no puedes utilizar directamente nuestro CMR electrónico, puedes indicar esos datos manualmente, importar un archivo, o conectar tu protaforma con la nuestra. En caso de que puedas o no quieras identificar estos viajes, simplemente prorratea el total de viajes vacíos en un periodo entre los clientes de ese periodo teniendo en cuenta el kilotonelaje de cada uno.
          </p>
          <br></br>
          <p className="text-intend-30p">
          En definitiva, realizar el reporte de las emisiones de CO2 de tus camiones es una tarea compleja, pero con Traxain te ayudamos a hacerlo de manera sencilla y eficiente. POr supuesto, puedes calcularlo por tu cuenta, pero siempre va a ser mucho más sencillo si cuentas con ayuda profesional. No dudes en consultarnos si tienes cuaquier duda y recuerda que puedes probar gratis la platafoma en <a href="https://www.drive-team.es">drive-team.es</a>.
          </p>
      </div>
      
    ),
  },

  {
    id: 8,
    title: "Reporte de emisiones en Francia 2025",
    summary:
      "Declaración de Emisiones de CO₂ en Francia (Actualización 2025)",
    path: "calculo-emisiones-co2-transporte-camiones-francia",
    author: "Ignacio Pardo",
    date: "27 de Diciembre de 2024",
    imageUrl: "/camion-francia.webp",
    ContentComponent: (content) => (
      <div className="text-align-justify">
    
    <h2 className="fw-bold">Reporte de emisiones en Francia 2025</h2>
<p className="text-intend-30p">
Declaración de Emisiones de CO₂ en Francia (Actualización 2025)
</p>
<br></br>
<p className="text-intend-30p">
Información sobre las emisiones de Gases de Efecto Invernadero en Francia  
En Francia, la obligación de declarar las emisiones de CO₂ se estableció en 2013 y se extendió al resto de gases de efecto invernadero en 2016. A partir del 1 de enero de 2025, se han establecido sanciones en caso de incumplimiento de dicha obligación.
</p>
<br></br>

<br></br><br></br>
<h2 className="fw-bold">¿Se extiende la normativa al transporte internacional operado por transportistas extranjeros?</h2>
<p className="text-intend-30p">
En la actualidad, no existe certeza absoluta acerca de la obligatoriedad de la declaración de emisiones para transportes internacionales con origen o destino en Francia. Hasta hace poco, la postura mayoritaria apuntaba a que la presentación de esta información era exigible, conforme a lo indicado en un seminario de la IRU. Sin embargo, la situación se ha vuelto menos clara recientemente.
</p>
<br></br>
<p className="text-intend-30p">
El artículo 1431.3 del Código de Transporte establece que “las condiciones en que se aplica la obligación definida en el párrafo primero a los servicios de transporte cuyo origen o destino se halle fuera del territorio nacional, se precisarán una vez que se adopten las disposiciones correspondientes en el marco de los organismos europeos e internacionales competentes”. Si tales disposiciones se refieren a la normativa europea, esta aún está en proceso de tramitación; si aluden a la norma ISO 14083, esta ya ha sido aprobada.
</p>
<br></br>
<p className="text-intend-30p">
Mientras tanto, se recomienda estar preparados para ofrecer esta información, sobre todo considerando que su presentación sí resulta obligatoria en el transporte nacional, incluyendo el cabotaje. Aunque finalmente no llegase a imponerse para transportes internacionales con origen o destino en Francia, muchos clientes podrían exigirla a partir de enero, y nada impide facilitarla de forma voluntaria.
</p>
<br></br>

<br></br><br></br>
<h2 className="fw-bold">¿Quién está obligado a declarar las emisiones?</h2>
<p className="text-intend-30p">
Según el artículo 141.3 del Código de Transporte, toda persona que organice o comercialice un servicio de transporte debe proporcionar esta declaración. Esto implica:
</p>
<br></br>
<RoundEnumeratedList 
  items={[
    "Para el transporte de pasajeros: comunidades territoriales organizadoras de los servicios y agencias de viaje.",
    "Para el transporte de mercancías: intermediarios y cargadores, siempre que no efectúen el transporte por cuenta propia."
  ]} 
/>
<p className="text-intend-30p">
Aunque los transportistas no tengan la obligación de declarar directamente a la administración, deben suministrar a sus clientes los datos necesarios para que estos últimos puedan cumplir con su responsabilidad de informar.
</p>
<br></br>

<br></br><br></br>
<h2 className="fw-bold">¿A quién debe remitirse la declaración de emisiones?</h2>
<p className="text-intend-30p">
La declaración de emisiones se entrega al beneficiario del servicio. En el transporte de viajeros, el beneficiario es la persona que adquiere el billete o contrata el transporte; en el transporte de mercancías, es la persona que concierta el servicio con quien lo organiza o comercializa.
</p>
<br></br>

<br></br><br></br>
<h2 className="fw-bold">¿En qué momento debe presentarse la declaración de emisiones?</h2>
<p className="text-intend-30p">
En el transporte de viajeros, la información debe facilitarse antes de la realización del servicio, es decir, como máximo en la compra del billete o en la contratación del transporte.
</p>
<br></br>
<p className="text-intend-30p">
En el transporte de mercancías, el plazo de presentación depende de lo acordado entre las partes. Si no existe pacto alguno, se dispone de dos meses desde la finalización del servicio (desde la entrega de la mercancía al destinatario) para llevar a cabo la declaración.
</p>
<br></br>

<br></br><br></br>
<h2 className="fw-bold">¿Cómo debe efectuarse la declaración de emisiones?</h2>
<p className="text-intend-30p">
La declaración puede transmitirse a través de cualquier medio: correo electrónico, mensajes de texto (SMS, WhatsApp), correo postal o mediante un enlace que dirija a una página web con los datos de emisiones del prestador. En el transporte de mercancías, la información puede adjuntarse a la factura.
</p>
<br></br>

<br></br><br></br>
<h2 className="fw-bold">Contenido exigido en las declaraciones</h2>
<p className="text-intend-30p">
En el sector del transporte se diferencian dos fases de emisiones:
</p>
<br></br>
<RoundEnumeratedList
  items={[
    "Fase de funcionamiento: contempla las emisiones procedentes de la operación del vehículo (motor de combustión, y en su caso, equipos de refrigeración).",
    "Fase previa: incluye las emisiones relacionadas con la producción y el suministro del combustible, tales como la extracción, el refinado y el transporte del petróleo, así como el posterior envío del diésel al punto de venta."
  ]}
/>
<p className="text-intend-30p">
Es obligatorio expresar las emisiones en kilogramos equivalentes de CO₂ (KgCO₂e).
</p>
<br></br>

<br></br><br></br>
<h2 className="fw-bold">Métodos de cálculo</h2>
<p className="text-intend-30p">
Se establecen cuatro niveles de referencia para el cálculo de emisiones:
</p>
<br></br>

<br></br><br></br>
<h2 className="fw-bold">Nivel 1</h2>
<p className="text-intend-30p">
Se aplica cuando la empresa tiene menos de 50 trabajadores, cuando no se dispone de datos fiables por subcontratación o para medios de transporte nuevos. Este método emplea coeficientes establecidos por kilómetro o litro de combustible, según las directrices oficiales.
</p>
<br></br>
<RoundEnumeratedList
  items={[
    "Para un vehículo articulado de 40 toneladas destinado a larga distancia con motor de combustión, la guía contempla 0,66 KgCO₂e en la fase previa y 2,51 KgCO₂e en la fase de funcionamiento, sumando 3,17 KgCO₂e por litro de combustible.",
    "Si se desconoce la cantidad de combustible consumida, pero se dispone del kilometraje, se multiplica el número de kilómetros por 0,338 para determinar el volumen de diésel.",
    "Para calcular la carga media en un vehículo articulado, la guía señala 12,5 toneladas, arrojando un resultado aproximado de 86 gCO₂e/tonelada-kilómetro.",
    "Asimismo, deben añadirse los kilómetros recorridos en vacío, ya sea imputándolos al trayecto siguiente o distribuyéndolos entre los servicios efectuados en un período dado."
  ]}
/>

<br></br><br></br>
<h2 className="fw-bold">Nivel 2</h2>
<p className="text-intend-30p">
Este nivel se basa en los valores promedio de la actividad del prestador, manteniendo las fórmulas indicadas en el nivel 1, pero utilizando datos relativos al consumo y a las toneladas según los promedios propios de la empresa en un intervalo máximo de tres años, actualizándolo periódicamente. Puede no resultar adecuado si se tienen operaciones muy diversas (flotas mixtas, múltiples servicios, etc.).
</p>
<br></br>

<br></br><br></br>
<h2 className="fw-bold">Nivel 3</h2>
<p className="text-intend-30p">
Se basa en el mismo método del nivel 2, pero separando la información por tipo de servicio, vehículo, ruta, cliente u otras categorías que se consideren oportunas.
</p>
<br></br>

<br></br><br></br>
<h2 className="fw-bold">Nivel 4</h2>
<p className="text-intend-30p">
Este método se nutre de mediciones reales, ya sea del consumo de energía en cada trayecto o de las toneladas efectivamente transportadas (o bien de al menos uno de dichos indicadores).
</p>
<br></br>

<p className="text-intend-30p">
En conclusión, aunque persisten dudas sobre la aplicación de estas obligaciones al transporte internacional con origen o destino en Francia, se aconseja prepararse para cumplir con la normativa. Por añadidura, muchos clientes requerirán estos datos a partir de enero, por lo que disponer de ellos y facilitarlos, sea por imperativo legal o de manera voluntaria, constituye una práctica altamente recomendable.
</p>
<br></br>

      </div>
      
    ),
  },
  {
    id: 10,
    title: "Responsabilidades y Regulaciones en la Carga de Camiones en España",
    summary:
      "El proceso de carga de camiones en España requiere planificación, cumplimiento normativo y coordinación. Las leyes como la LOTT y el ADR regulan la seguridad de carga, documentación y condiciones laborales.",
    path: "carga-descarga-camiones",
    author: "Ignacio Pardo",
    date: "22 de Enero de 2024",
    imageUrl: "/descarga.webp",
    ContentComponent: (content) => (
      <div className="text-align-justify">
        <br></br><br></br>
        <p className="text-intend-30p">
        En España, la carga de camiones implica diversas responsabilidades para empresas de transporte y trabajadores. Este proceso exige organización, cumplimiento de normativas y medidas de seguridad para garantizar la eficiencia logística y la protección de mercancías.
Planificación y Organización
</p>
        <h2 className="fw-bold">Planificación y Organización</h2>
        <p className="text-intend-30p">
        Las empresas deben coordinar las operaciones para minimizar tiempos de espera, asignar recursos adecuados, y garantizar el equipo necesario para la carga.
        </p>
        <br></br>
        <br></br>
        <h2 className="fw-bold">Cumplimiento Normativo</h2>
        <p className="text-intend-30p">
        La normativa nacional y europea regula el transporte, cubriendo aspectos como seguridad laboral, pesaje máximo y mercancías peligrosas. Además, es crucial gestionar correctamente la documentación, incluidos albaranes y permisos.
        </p>
        <br></br>
        <br></br>
        <h2 className="fw-bold">Responsabilidades del Cargador</h2>
        <p className="text-intend-30p">
        Se debe garantizar la correcta distribución y fijación de la carga para evitar desplazamientos que puedan causar accidentes. También es esencial inspeccionar las condiciones de la mercancía y asegurar que coincida con la documentación entregada.
        </p>
        <br></br>
        <br></br>
        <h2 className="fw-bold">Seguridad Laboral</h2>
        <p className="text-intend-30p">
        Se exige el uso de equipos de protección personal (EPP) y mantener condiciones seguras en el entorno de trabajo.
        </p>
        <br></br>
        <br></br>
        <h2 className="fw-bold">Logística y Operaciones</h2>
        <p className="text-intend-30p">
        Optimizar el espacio disponible en el camión y respetar los tiempos de carga es clave para la eficiencia de la cadena logística.
        </p>
        <br></br>
        <br></br>
        <h2 className="fw-bold">Responsabilidades del Transportista</h2>
        <p className="text-intend-30p">
        El transportista debe realizar inspecciones finales, asegurar el cierre de puertas y monitorear la carga durante el viaje para actuar ante cualquier incidencia.
        </p>
        <br></br>
        <br></br>
        <h2 className="fw-bold">Legislación Aplicable</h2>
        <RoundEnumeratedList items={["Ley 16/1987 (LOTT): Marco general del transporte terrestre.", "Ley 15/2009: Regula contratos de transporte y responsabilidades.", "Reglamento (CE) nº 561/2006: Normas sobre tiempos de conducción y descanso.", "ADR: Transporte de mercancías peligrosas."]} />
        <br></br>
        <br></br>
        <h2 className="fw-bold">Modificaciones del Real Decreto 70/2019</h2>
        <p className="text-intend-30p">
        Este decreto introduce cambios como la reducción del número mínimo de vehículos para obtener autorización de transporte y la obligatoriedad de tramitación electrónica.
        </p>
        <br></br>
      </div>
    ),
  },
  {
    id: 1,
    title: "¿Qué es Drive-Team.es?",
    summary:
      "Saca provecho a los datos de tus tacógrafos. Traxain te ayuda usando los datos legalmente válidos del tacógrafo para calcular y controlar tus gastos en personal",
    path: "drive-team-tgd",
    author: "Ignacio Pardo",
    date: "4 de Marzo de 2024",
    imageUrl: "/captura_sobre.webp",
    ContentComponent: (content) => (
      <div className="text-align-justify">
        <p className="text-intend-30p">
        <a href="https://www.drive-team.es">Drive-Team.es</a> es la plataforma para calcular automáticamente las dietas de los conductores de camión y autobús, tanto en el transporte
          nacional como internacional por carretera. Simplemente sube los archivos del tacógrafo, del vehículo o del conductor, y Drive-Team.es te
          dará un reporte de las actividades, del cumplimiento de jornadas y un desglose de las dietas.
        </p>
        <br></br>
        <br></br>
        <h2 className="fw-bold">¿Quién está detrás de Drive-Team.es?</h2>
        <br></br>
        <p className="text-intend-30p">
          Drive-Team.es es uno de los productos ofrecidos por Traxain. Traxain S.L es una Startup española dedicada a solucionar los problemas del
          transporte por carretera a través del uso de la tecnología. Trabajamos constantemente para mejorar la vida de todos los implicados en este
          sector, que es el corazón de toda la economía moderna.
        </p>
        <br></br>
        <br></br>
        <h2 className="fw-bold">¿Por qué creamos Drive-Team.es?</h2>
        <br></br>
        <p className="text-intend-30p">
          La escasez internacional de conductores de camión ha provocado aumentos significativos en los costes de las empresas de transporte. En este
          contexto es más necesario que nunca optimizar nuestro flujo de efectivo y hacer el mejor uso posible de nuestros recursos. Para ello{" "}
          Drive-Team.es ofrece 5 capacidades clave:</p>
          <RoundEnumeratedList items={paragraphs} />
        
        <br></br>
        <br></br>

        <h2 className="fw-bold">¿Qué hace especial a Drive-Team.es?</h2>
        <br></br>
        <p className="text-intend-30p">
          Lo que hace especial a Drive-Team.es es que utiliza directamente los datos del Tacógrafo digital de las unidades, para lo que trabajamos con
          nuestro socio Continental. Contar con los datos del Tacógrafo nos permite calcular exactamente los costes en los que tendrá que incurrir la
          empresa utilizando los datos que son legalmente vinculantes.
        </p>
        <br></br>
        <br></br>
        <h2 className="fw-bold">¿Cómo lo hacemos?</h2>
        <br></br>
        <p className="text-intend-30p">
          Nuestro objetivo es que tanto las empresas de transporte como los conductores puedan saber por anticipado cuánto van a cobrar y saber que
          este cálculo se ha realizado de manera imparcial utilizando los datos del Tacógrafo, que es imposible falsificar. Para ello ofrecemos un
          Dashboard que permite ver en tiempo real las actividades de los empleados y comprobar el tiempo de conducción, descanso, otros trabajos y
          disponibilidad. Con este Dashboard se pueden comparar los tiempos de trabajo de los distintos empleados, y se puede ver una estimación de
          salario que habrá que pagar a final de mes.
        </p>
        <br></br>
        <br></br>
        <h2 className="fw-bold">¿Es complicado de instalar?</h2>
        <br></br>
        <p className="text-intend-30p">Para nada, basta con tener un usuario y una contraseña, y acceder a Drive-Team.es para usarlo.</p>
        <br></br>
        <br></br>
        <h2 className="fw-bold">¿No estás convencido aún?</h2>
        <br></br>
        <p className="text-intend-30p">
          No hay problema. Ponte en contacto con nosotros escribiendo a ignacio@traxain.com, en Traxain.com o al teléfono +34 638269633 y podrás
          probar Drive-Team.es sin compromiso.
        </p>
        <br></br>
        <br></br>
        <h2 className="fw-bold">¿No es lo que buscabas?</h2>
        <br></br>
        <p className="text-intend-30p">
          Si Drive-Team.es no es lo que buscas, ¡Dinos qué buscas! Podemos ponerte en contacto con otros proveedores, o diseñar una solución para tí
          sin compromiso.
        </p>
        <br></br>
      </div>
    ),
  }
];

export { blogListTotal };
