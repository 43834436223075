// import React, { Component, useContext,  useEffect  } from 'react'
// import UploadXLSFile from './UploadXLSFile'
// import { parseISOWithOptions } from 'date-fns/fp'
// import AskInstall from './AskInstall';
// import PushNotification from './PushNotification';
// import Login from './Login'<
// import Register from './Register'


import React, { Component } from "react";
import Navbar from "./Navbar";
import DatePicker from "react-datepicker";
import EmployeeDetails from "./EmployeeDetails";
import EmployeeGraphic from "./EmployeeGraphic";
import ErrorHappened from "./ErrorHappened";
import ErrorExpirated from "./ErrorExpirated";
import fondoAvif from "../carretera.avif";
import camionesAvif from "../camiones_aparcados.avif";
import fondoPNG from "../carretera.png";
import camionesPNG from "../camiones_aparcados.png";
import camionesMovimiento from "../fondomovimiento.webp";
import List from "./List";
import ListManager from "./ListManager";
import Actions from "./Actions";
import Creation from "./Creation";
import CreationManager from "./CreationManager";
import GeneralView from "./GeneralView";
import ModalActivity from "./ModalActivity";
import SendSmsActivity from "./SendSmsActivity";
import ModalChangePass from "./ModalChangePass";
import NavigatorModal from "./NavigatorModal";
import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";
import TripCreation from "./TripCreation";
import UploadTGDFile from "./UploadTGDFile";
import JustToTest from "./JustToTest";
import Footer from "./Footer";
import HolidayEmployee from "./HolidayEmployee";
import ModalPeriod from "./ModalPeriod";
import ModalTrip from "./ModalTrip";
import CheckState from "./CheckState";
import axios from "axios";
import CustomSpinner from "./CustomSpinner";
import "@fortawesome/fontawesome-free/css/all.css";
import fondoMovil from "../camiones_aparcados.avif";
import truck from "../truck.webp";
import TripsListTotal from "./TripsListTotal";
import ModalCMR from "./ModalCMR";
import CreationCMR from "./CreationCMR";
import ClientsListTotal from "./ClientsListTotal";
import ModalEmployeeAlpha from "./ModalEmployeeAlpha";
import About from "./About";


class Home extends Component {
  constructor(props) {
    super(props);
    this.childRefEmployee = React.createRef();
    this.childRefGeneral = React.createRef();
    this.childRefHandle = React.createRef();
    this.childRefDisplayAct = React.createRef();

    this.state = {
      endPoint: "http://localhost:8000/",
      //endPoint: "https://api.ecargo.link/",
      //endPoint: "https://api.traxain.xyz/",

      myUser: {'mail':"",'user_id':"" },
      camionesFinal: camionesPNG,
      dateHasta: new Date() -1,
      // make dateDesde = new Date() - 1 month
      dateDesde: new Date() - 1 * 30 * 24 * 60 * 60 * 1000,
      fondoFinal: fondoPNG,
      absoluteCO2: 0,
      absoluteCO2Trips: 0,
      filterAlerts: false,
      filterDiets: false,
      showTrip: true,
      filter_trip_by_client: [],
      allowed: "",
      winWidth: 0,
      winHeight: 0,
      chargeStatusTGD: 0,
      loading: false,
      employeesList: [],
      managersList: [],
      activitiesList: [],
      thisEmloyeeId: {},
      myEmployee: {},
      somethingSearched: 0,
      wantToCreate: true,
      wantToCreateManager: false,
      dontCall: false,
      userRole: "",
      errorHappened: false,
      errorExpirated: false,
      myEmployeeCount: "",
      device: "",
      date: "",
      errorMsg: "",
      selectedMode: "",
      userToken: "",
      currentUser: null,
      product: "autonomina",
      askLogin: 6,
      displayType: "text",
      start: 0,
      end: 0,
      originTrip: "",
      destinationTrip: "",
      modeTrip: "",
      dateTrip: 0,
      filters: {},
      generalView: [],
      showModalActivity: false,
      showSendSmsActivity: false,
      showGraphic: false,
      showLastModal: "",
      selectedActivity: 0,
      selectedActivityDict: {},
      listInit: 0,
      activityListInit: 0,
      tripsListInit: 0,
      clientsListInit: 0,
      defaultComments: "",
      defaultPlace: "",
      defaultIdentifier: "",
      employeesSelectedToShow: [],
      employeesSelected: [],
      askContCred: false,
      newPassAsked: false,
      showANDirecto: false,
      showNavigatorModal: false,
      showLoginModalVar: false,
      showRegisterModalVar: false,
      showCreateTripFields: false,
      showPeriodModal: false,
      showTripModal: false,
      showCheckStateModal: false,
      activeProcessId: 0,
      selectedPeriod: 0,
      selectedTrip: 0,
      employees: [],
      tac_provider: "",
      askTacProvider: false,
      showPlaceholderOthers: false,
      upLoadTGDManually: true,
      periodsListInit: 0,
      stringToSearch: "",
      showList: true,
      showListManager: false,
      pointer: 0,
      chartType: "scatter",
      showSimulator: false,
      showTestDetails: false,
      showHolidays: false,
      pageFullyLoaded: false,
      numberOfFiles: 0,
      passedCalls: 0,
      stateHistory: [],
      stateFuture: [],
      backClicsCount: 0,
      childHistory: [],
      childFuture: [],
      filteredActivities: [],
      filteredActivitieForEdit: [],
      estadosGuardados: [],
      reloadEmployees: false,
      tripsTotal: [],
      tripsBackup: [],
      showTripsTotal: false,
      clientsTotal: [],
      showClientsTotal: false,
      dataGraphic: {},
      noWallet: true,
      isFeatureEnabled: false,
      isShowModalEmployeeAlpha: false,
      isReloadTrips: false,
      isReloadActivities: false,
      isReloadDayConsult: false,
      isToggleShowHolidays: false,
      isToggleCreateTrip: false
    };
    this.tripsAndClients = this.tripsAndClients.bind(this);
    this.createTrip = this.createTrip.bind(this);
    this.errorStatus = this.errorStatus.bind(this);
    this.selectAccessMode = this.selectAccessMode.bind(this);
    this.showError = this.showError.bind(this);
    this.selectProduct = this.selectProduct.bind(this);
    this.showCreationFields = this.showCreationFields.bind(this);
    this.showCreationManager = this.showCreationManager.bind(this);
    this.createEmployee = this.createEmployee.bind(this);
    this.createManager = this.createManager.bind(this);
    this.employees_manager = this.employees_manager.bind(this);
    this.usersEmployeeCount = this.usersEmployeeCount.bind(this);
    this.usersManagerCount = this.usersManagerCount.bind(this);
    this.usersEmployeeLoad = this.usersEmployeeLoad.bind(this);
    this.search = this.search.bind(this);
    this.dowloadEmployeeReport = this.dowloadEmployeeReport.bind(this);
    this.seslectDisplayMode = this.seslectDisplayMode.bind(this);
    this.showModalActivity = this.showModalActivity.bind(this);
    this.showSendSmsActivity = this.showSendSmsActivity.bind(this);
    this.showGraphic = this.showGraphic.bind(this);
    this.showModalPeriod = this.showModalPeriod.bind(this);
    this.showEditTripFields = this.showEditTripFields.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onCloseNav = this.onCloseNav.bind(this);
    this.onCloseLog = this.onCloseLog.bind(this);
    this.onCloseReg = this.onCloseReg.bind(this);
    this.editActivityDetails = this.editActivityDetails.bind(this);
    this.editSendSms = this.editSendSms.bind(this);
    this.editPeriodDetails = this.editPeriodDetails.bind(this);
    this.editTripDetails = this.editTripDetails.bind(this);
    this.showCheckState = this.showCheckState.bind(this);
    this.processNumber = this.processNumber.bind(this);
    this.deleteTrip = this.deleteTrip.bind(this);
    this.editEmployeeListInit = this.editEmployeeListInit.bind(this);
    this.editActivityListInit = this.editActivityListInit.bind(this);
    this.editEmployeesSelected = this.editEmployeesSelected.bind(this);
    this.editContinentalCredentials = this.editContinentalCredentials.bind(this);
    this.editRole = this.editRole.bind(this);
    this.askNewPass = this.askNewPass.bind(this);
    this.assignNewPass = this.assignNewPass.bind(this);
    this.getOldPass = this.getOldPass.bind(this);
    this.getParams = this.getParams.bind(this);
    this.selectANDirecto = this.selectANDirecto.bind(this);
    this.showNavigatorModalHome = this.showNavigatorModalHome.bind(this);
    this.showLoginModal = this.showLoginModal.bind(this);
    this.showRegisterModal = this.showRegisterModal.bind(this);
    this.showCreateTripFields = this.showCreateTripFields.bind(this);
    this.editTripsListInit = this.editTripsListInit.bind(this);
    this.editClientsListInit = this.editClientsListInit.bind(this);
    this.editClient = this.editClient.bind(this);
    this.editTacProvider = this.editTacProvider.bind(this);
    this.putLoading = this.putLoading.bind(this);
    this.editPeriodsListInit = this.editPeriodsListInit.bind(this);
    this.searchManual = this.searchManual.bind(this);
    this.dowloadActivityReport = this.dowloadActivityReport.bind(this);
    this.dowloadPeriodsReport = this.dowloadPeriodsReport.bind(this);
    this.activateSimulator = this.activateSimulator.bind(this);
    this.setEmployee = this.setEmployee.bind(this);
    this.showHolidays = this.showHolidays.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.putFilterAlerts = this.putFilterAlerts.bind(this);
    this.selectFilterAlerts = this.selectFilterAlerts.bind(this);
    this.putFilterDiets = this.putFilterDiets.bind(this);
    this.filtrarDietas = this.filtrarDietas.bind(this);
    this.limpiarDietas = this.limpiarDietas.bind(this);
    this.deshacerFiltrado = this.deshacerFiltrado.bind(this);
    this.actualizarEstadoYAlmacenar = this.actualizarEstadoYAlmacenar.bind(this);
    this.volverAlPasado = this.volverAlPasado.bind(this);
    this.regresoAlFuturo = this.regresoAlFuturo.bind(this);
    this.hideTrip = this.hideTrip.bind(this);
    this.seleccionBotonAlpha = this.seleccionBotonAlpha.bind(this);
    this.seleccionBoton = this.seleccionBoton.bind(this);
    this.showModalEmployeeAlpha = this.showModalEmployeeAlpha.bind(this);
    this.onCloseListAlpha = this.onCloseListAlpha.bind(this);
    this.createEmployeeAlpha = this.createEmployeeAlpha.bind(this);
    this.toggleIsReloadTrips = this.toggleIsReloadTrips.bind(this);
    this.toggleIsReloadActivities = this.toggleIsReloadActivities.bind(this);
    this.toggleIsReloadDayConsult = this.toggleIsReloadDayConsult.bind(this);
    this.toggleShowHolidays = this.toggleShowHolidays.bind(this);
    this.toggleCreateTrip = this.toggleCreateTrip.bind(this);

  }

  async componentDidMount(recalled = 0) {
    await this.preparePage();

    let params = await this.getParams();
    const token = localStorage.getItem("TraxainUserToken");

    if (token && token !== "null" && (params === "nothing" || !("tk" in params) || params["tk"] === null)) {
      await this.handleTokenAndParams(token, params);
    } else if (params !== "nothing") {
      await this.handleParams(params);
    } else {
      await this.handleNoTokenAndParams();
    }

    await this.setState({ pageFullyLoaded: true });
  }


  handleChangeDesde(dateDesde) {
    try {
      // parse dateDesde from date to etimestamp
      dateDesde = dateDesde.getTime();
      
      this.setState({
        dateDesde: dateDesde,
      });
    } catch (err) {
      alert("Error al indicar la fecha inicial");
    }
  }

  handleChangeHasta(dateHasta) {
    try {
      //get desdeHasta timestamp
      dateHasta = dateHasta.getTime();
      this.setState({
        dateHasta: dateHasta,
      });
    } catch (err) {
      alert("Error al indicar la fecha final");
    }
  }


  async preparePage() {
    await this.lookForEndpoint();
    window.onload = () => window.scrollTo(0, 0);
    this.foundImage();

    const { deviceType, winWidth } = await this.getDeviceInfo();
    await this.setState({ device: deviceType, winWidth });
  }

  async getDeviceInfo() {
    const deviceType = await this.getDeviceType();
    const winWidth = window.innerWidth;
    return { deviceType, winWidth };
  }

  async handleTokenAndParams(token, params) {
    await this.setState({ userToken: token });
    const response = await this.getUserRole(token);

    if (!response.ok) {
      throw new Error("Request failed with status " + response.status);
    }

    const userData = await response.json();
    await this.setState({ currentUser: userData["user_id"], userRole: userData["role"] });

    if (userData["role"] !== "employee") {
      await this.handleNonEmployee(userData, params);
    } else {
      await this.handleEmployee(userData, params);
    }
  }

  async handleParams(params) {
    this.putFilterAlerts(true);
    await this.setState({ displayType: "list" });

    let newToken = null;
    let filters = Object.keys(params["filters"]).length === 0 ? {} : JSON.parse(params["filters"]);

    try {
      newToken = params["tk"];
    } catch {}

    if (!newToken) {
      newToken = this.state.userToken;
    }

    if (newToken) {
      const response = await this.getUserRole(newToken);
      if (response.status === 401) {
        localStorage.setItem("TraxainUserToken", "null");
        this.setState({ errorExpirated: true, errorMsg: "Tiempo expirado. Por favor consulte con su administrador" });
      } else if (!response.ok) {
        localStorage.setItem("TraxainUserToken", "null");
        throw new Error("Request failed with status " + response.status);
      } else {
        localStorage.setItem("TraxainUserToken", newToken);
        this.setState({ userToken: newToken });
      }
      const userData = await response.json();
      await this.setState({ userRole: userData["role"] });

      const empIdentification = parseInt(userData["empIdentification"]);

      let myEmployee = await this.search(empIdentification, parseInt(params["start"]), parseInt(params["end"]), filters, true);
      if (params["listToEdit"]) {
        await this.setState({ listToEdit: params["listToEdit"] });

        // get the myEmployee.dataToAnanlize, and filter just the activities with id in params["filters"]["listToEdit"]
        let listToFilter = myEmployee.dataToAnalize;

        // Assuming params["filters"]["listToEdit"] contains the list of ids to keep

        // idsToKeep is a string with the ids separated by commas, make it an array of integers
        let idsToKeep = params["listToEdit"].split(",").map(Number);
        // Filter activities where the id is included in the idsToKeep array
        let filteredActivities = listToFilter.filter((activity) => idsToKeep.includes(activity.id));

        myEmployee.dataToAnalize = filteredActivities;

        await this.setState({ myEmployee: myEmployee });
        await this.setState({ filteredActivitieForEdit: filteredActivities });
      }
      await this.setState({ somethingSearched: 2 });
    } else {
      this.setState({ userToken: "null" });
      await this.setState({ somethingSearched: 0 });
      await this.setState({ loading: false });
    }
  }

  async handleNonEmployee(userData, params) {
    this.setState({ userRole: userData["role"], selectedMode: "Conventional" });

    if (!userData.ok && userData.type === "cors") {
      await this.setState({
        errorHappened: true,
        errorMsg: "Aún no tenemos datos para ti. Contáctanos en traxain.com, escribiendo a ignacio@traxain o llamando al +34 638 26 96 33 (1)",
      });
    } else {
      const modes = [
        { label: "Disponible", value: "available" },
        { label: "Conducción", value: "driving" },
        { label: "Descanso", value: "resting" },
        { label: "Trabajo", value: "working" },
      ];


      if (params["empId"] !== 0 && params !== "nothing") {
        await this.usersEmployeeCount(0, 0, "text", { modes: modes });
        let empId = parseInt(params["empId"]);

        await this.setState({ displayType: "list" });
        this.putFilterAlerts(true);

        let newToken = null;
        let filters;

        if (Object.keys(params["filters"]) === undefined || Object.keys(params["filters"]).length === 0) {
          filters = {};
        } else {
          filters = JSON.parse(params["filters"]);
        }


        await this.search(empId, parseInt(params["start"]), parseInt(params["end"]), filters, true);
        await this.setState({ somethingSearched: 2 });
        await this.showCreationFields();
      } else {
        await this.usersEmployeeCount(0, 0, "text", { modes: modes });

      }
    }
  }

  async handleEmployee(userData, params) {
    const empIdentification = parseInt(userData["empIdentification"]);
    if (params === "nothing") {
      await this.setState({ somethingSearched: 0 });
      await this.search(empIdentification, 0, 0, {}, true);
    } else {
      await this.setState({ displayType: "list" });

      let newToken = null;

      let filters = Object.keys(params["filters"]).length === 0 ? {} : JSON.parse(params["filters"]);

      if (params["tk"]) {
        newToken = params["tk"];
      }

      localStorage.setItem("TraxainUserToken", newToken);

      await this.search(empIdentification, parseInt(params["start"]), parseInt(params["end"]), filters, true);
      await this.setState({ somethingSearched: 2 });
    }
  }

  async handleNoTokenAndParams() {
    this.setState({ userToken: "null" });
  }

  async getUserRole(token) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    try {
      console.log(this.state.endPoint + "user-role/")
      const response = await fetch(this.state.endPoint + "user-role/", requestOptions);
      console.log(response)
      if (!response.ok && response.type === "cors") {
        localStorage.setItem("TraxainUserToken", "null");
        //   setTimeout(function() {
        //     window.location.href = "https://drive-team.es";
        // }, 1000)
      }
      return response;
    } catch (err) {
      localStorage.setItem("TraxainUserToken", "null");
      //   setTimeout(function() {
      //     window.location.href = "https://drive-team.es";
      // }, 1000)

    }
  }

  foundImage = async () => {
    const loadImage = async (src, successCallback, errorCallback) => {
      const img = new Image();
      img.src = src;
      img.onerror = errorCallback;
      img.onload = successCallback;
    };

    const setImageState = (imgSrc, successState, errorState) => {
      loadImage(
        imgSrc,
        () => this.setState(successState),
        () => this.setState(errorState)
      );
    };

    setImageState(camionesAvif, { camionesFinal: camionesMovimiento }, { camionesFinal: camionesPNG });
    setImageState(fondoAvif, { fondoFinal: fondoAvif }, { fondoFinal: fondoPNG });
  };

  /**
 * Fetch with a timeout using AbortController
 * @param {string} resource - The URL to fetch
 * @param {object} options - The fetch options (headers, etc.)
 * @param {number} timeout - Timeout in milliseconds
 */
async fetchWithTimeout(resource, options = {}, timeout = 3000) {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  
  try {
    const response = await fetch(resource, {
      ...options,
      signal: controller.signal,
    });
    return response;
  } finally {
    // Clear the timeout so it doesn’t trigger if the response arrives
    clearTimeout(id);
  }
}

async lookForEndpoint() {
  const endpointlocal = "http://localhost:8000/";
  const endpoint1 = "https://api.traxain.xyz/";
  const endpoint2 = "https://api.ecargo.link/";

  let response;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    console.log("Try local...");
    // use fetchWithTimeout (3 seconds)
    response = await this.fetchWithTimeout(
      endpointlocal + "api/users/visitaWeb",
      requestOptions,
      500 // 3-second timeout
    );

    // If we got a response but it's not OK (non-2xx status), throw
    if (!response.ok) {
      throw new Error("Request failed with status " + response.status);
    }

    // If success, set the endpoint
    this.setState({ endPoint: endpointlocal });
  } catch (err) {
    // Could be a fetch error, timeout, etc.
    console.log("Local endpoint failed or timed out, trying endpoint1...");

    try {
      response = await this.fetchWithTimeout(
        endpoint1 + "api/users/visitaWeb",
        requestOptions,
        3000
      );

      if (!response.ok) {
        throw new Error("Request failed with status " + response.status);
      }

      this.setState({ endPoint: endpoint1 });
    } catch (err) {
      console.log("Endpoint1 failed or timed out, trying endpoint2...");

       try {
        response = await this.fetchWithTimeout(
          endpoint2 + "api/users/visitaWeb",
          requestOptions,
          3000
        );

        if (!response.ok) {
          throw new Error("Request failed with status " + response.status);
        }

        this.setState({ endPoint: endpoint2 });
      } catch (err) {
        console.log("All endpoints failed or timed out. No connection.");
      }
    }
  }
}

  async searchManual(input) {
    try {
      await this.setState({ showList: false });

      const results = this.state.employees.filter((employee) => {
        const searchTerms = input.toString().toLowerCase();

        // Comprueba si alguno de los campos contiene las palabras clave de búsqueda
        return (
          employee.name.toLowerCase().includes(searchTerms) ||
          employee.apellidos.toLowerCase().includes(searchTerms) ||
          employee.cardNumber.toLowerCase().includes(searchTerms)
        );
      });

      // Espera a que se complete la búsqueda y devuelve los resultados
      await this.setState({
        stringToSearch: input,
        employeesList: results,
        showList: true,
      });
    } catch (error) {
      throw error;
    }
  }

  async getDeviceType() {
    try {
      let ua = await navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
      }
      if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return "mobile";
      }
      return "desktop";
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error al detectar el tiempo de dispositivo" });
    }
  }

  async putFilterAlerts(force = false) {
    if (force) {
      await this.setState({ filterAlerts: true });
    } else {
      let filterAlerts = this.state.filterAlerts;

      if (filterAlerts) {
        await this.setState({ filterAlerts: false });
      } else {
        await this.setState({ filterAlerts: true });
      }
    }
  }

  async selectFilterAlerts() {
    if (this.state.filterAlerts === true) {
      await this.setState({ filterAlerts: false });
    } else if (this.state.filterAlerts === false) {
      await this.setState({ filterAlerts: true });
    }
  }

  scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  async putFilterDiets() {
    let filterDiets = this.state.filterDiets;
    if (filterDiets === false) {
      await this.setState({ filterDiets: true });

      this.filtrarDietas();
    }

    // else if (filterDiets === true){
    //   await this.setState({filterDiets:false})

    //   this.deshacerFiltrado()
    // }
  }

  filtrarDietas = async () => {
    const originalActivities = this.state.myEmployee.dataToAnalize; // Usar el estado original
    var filteredActivities = [];
    for (let i = 0; i < originalActivities.length; i++) {
      if (originalActivities[i]["dietType"] !== "No") {
        filteredActivities.push(originalActivities[i]);
      }
    }
    this.setState({
      filteredActivities: filteredActivities,
    });
  };

  limpiarDietas = async () => {
    this.setState({ filteredActivities: [] });
  };

  deshacerFiltrado = async () => {
    const originalActivities = this.state.myEmployee.dataToAnalize; // Usar el estado original
    this.setState({
      filteredActivities: originalActivities,
    });
  };

  actualizarEstadoYAlmacenar = (nuevoEstado) => {

    if (!this.state.showTripModal) {
      const { stateHistory, ...restOfState } = this.state;
      const newState = { ...restOfState, ...nuevoEstado };

      this.setState(
        (prevState) => ({
          ...prevState,
          ...nuevoEstado,
          stateHistory: [...prevState.stateHistory, { ...prevState }],
        }),
        () => {}
      );
    }

  };

  volverAlPasado = () => {
    const { stateHistory, stateFuture } = this.state;

    if (stateHistory.length >= 1) {
      const newState = stateHistory[stateHistory.length - 1];
      const updatedHistory = stateHistory.slice(0, -1);

      this.setState({
        ...newState,
        stateHistory: updatedHistory,
        stateFuture: [...stateFuture, { ...this.state }],
      });
    }


    try {
      this.childRefGeneral.current.volverAlPasadoGeneral();
    } catch {}

    try {

      this.childRefEmployee.current.volverAlPasadoEmployees();
    } catch {}
  };

  regresoAlFuturo = () => {
    const { stateFuture } = this.state;

    if (stateFuture && stateFuture.length >= 1) {
      const nextState = stateFuture[stateFuture.length - 1];
      const updatedFuture = stateFuture.slice(0, -1);

      this.setState({
        ...nextState,
        stateFuture: updatedFuture,
      });
    }

    try {
      this.childRefEmployee.current.regresoAlFuturoEmployees();
    } catch {}

    try {
      this.childRefGeneral.current.regresoAlFuturoGeneral();
    } catch {}
  };

  async setEmployee(employee, ) {
    await this.setState({ myEmployee: employee });
  }

  async activateSimulator() {
    let showSimulator = this.state.showSimulator;
    this.setState({ wantToCreate: false });

    if (showSimulator) {
      this.setState({ showSimulator: false });
    } else {
      this.setState({ showSimulator: true });
    }

    this.setState({ wantToCreate: true });
  }

  async dowloadActivityReport() {
    try {
      let empId;
      let start;
      let end;
      let filters;

      if (this.state.myEmployee) {
        empId = this.state.myEmployee.id;
        start = this.state.start;
        end = this.state.end;
        filters = this.state.filters;
      } else {
        (empId = ""), (start = 0), (end = 0), (filters = {});
      }

      await this.setState({ loading: true });
      let token = this.state.userToken;

      var bodyJS = JSON.stringify({
        id: 0,
        start: start,
        end: end,
        displayType: "nada",
        filters: {},
      });
      let requestOptions = await {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: bodyJS,
      };

      var response = await fetch(this.state.endPoint + "create_report/a", requestOptions);

      var file = await response.blob();

      var fileURL = URL.createObjectURL(file);


      var a = document.createElement("a");

      a.href = fileURL;
      let date = new Date().toDateString();
      a.download = "Actividades" + "_" + date + ".xlsx";
      a.click();
      await this.setState({ loading: false });
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Error al descargar el reporte" });
      this.setState({ loading: false });
    }
  }

  async dowloadPeriodsReport() {
    try {
      await this.setState({ loading: true });
      let token = this.state.userToken;

      var bodyJS = JSON.stringify({
        id: 0,
        start: 0,
        end: 0,
        displayType: "nada",
        filters: {},
      });
      let requestOptions = await {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: bodyJS,
      };

      var response = await fetch(this.state.endPoint + "create_report/b", requestOptions);

      var file = await response.blob();

      var fileURL = URL.createObjectURL(file);

      var a = document.createElement("a");

      a.href = fileURL;
      let date = new Date().toDateString();
      a.download = "Por_dia" + "_" + date + ".pdf";
      a.click();
      await this.setState({ loading: false });
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Error al descargar el reporte" });
      this.setState({ loading: false });
    }
  }

  async dowloadEmployeeReport(e) {
    try {
      await this.setState({ loading: true });
      let token = this.state.userToken;
      var start = 0;
      var end = new Date();
      end = (await end.getTime()) / 1000;

      if (this.state.myEmployee) {
        start = new Date(this.state.myEmployee.startString);
        start = start.getTime() / 1000;
      }

      if (this.state.myEmployee) {
        end = new Date(this.state.myEmployee.endString);
        end = end.getTime() / 1000;
      }
      var bodyJS = JSON.stringify({
        id: 0,
        start: start,
        end: end,
        displayType: "nada",
        filters: {},
      });
      let requestOptions = await {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: bodyJS,
      };

      var response = await fetch(this.state.endPoint + "create_report/" + e.toString(), requestOptions);

      var file = await response.blob();

      var fileURL = URL.createObjectURL(file);

      var a = document.createElement("a");

      a.href = fileURL;
      let date = new Date().toDateString();
      a.download = "Reporte_" + e.toString() + "_" + date + ".pdf";
      a.click();
      await this.setState({ loading: false });
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Error al descargar el reporte" });
      this.setState({ loading: false });
    }
  }

  async putLoading(boolValue) {
    await this.setState({ loading: boolValue });
  }

  async showNavigatorModalHome() {



    try {
      let showNavigatorModal = this.state.showNavigatorModal;

      if (showNavigatorModal) {
        await this.setState({ showNavigatorModal: false });
      } else {
        await this.setState({ showNavigatorModal: true });
      }
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error al mostrar el elemento de navegación" });
    }

  }


  async showNavigatorModal() {



    try {
      let showNavigatorModal = this.state.showNavigatorModal;

      if (showNavigatorModal) {
        await this.setState({ showNavigatorModal: false });
      } else {
        await this.setState({ showNavigatorModal: true });
      }
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error al mostrar el elemento de navegación" });
    }

  }

  async showModalEmployeeAlpha() {
    try {
      let isShowModalEmployeeAlpha = this.state.isShowModalEmployeeAlpha;

      if (isShowModalEmployeeAlpha) {
        await this.setState({ isShowModalEmployeeAlpha: false });
      } else {
        await this.setState({ isShowModalEmployeeAlpha: true });
      }
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error al mostrar la lista de usuarios" });
    }
  }


  async showLoginModal() {
    try {
      let showLoginModal = this.state.showLoginModalVar;

      if (showLoginModal) {
        await this.setState({ showLoginModalVar: false });
      } else {
        await this.setState({ showLoginModalVar: true });
      }
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error al mostrar el elemento de Login" });
    }
  }

  async showRegisterModal() {
    try {
      let showRegisterModal = this.state.showRegisterModalVar;

      if (showRegisterModal) {
        await this.setState({ showRegisterModalVar: false });
      } else {
        await this.setState({ showRegisterModalVar: true });
      }
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error al mostrar el elemento de registro" });
    }
  }

  async editTacProvider(provider) {
    let token = this.state.userToken;

    let requestOptions = await {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        a: provider,
      }),
    };

    var response = await fetch(this.state.endPoint + "select_tac_provider/", requestOptions).then((response) => response.json());

    if (provider === "Tis") {
      this.setState({ askContCred: true });
    }
    if (provider === "Otro") {
      this.setState({ showPlaceholderOthers: true });
    }
  }

  // async createTrip(origin, destination, tripDate, mode) {

  //   try {

  //     await this.setState({ loading: true })

  //     await this.setState({ somethingSearched: 0 })

  //     var employee_id = this.state.myEmployee.id

  //     let token = this.state.userToken

  //     var empId = this.state.myEmployee.id
  //     var start = this.state.start
  //     var end = this.state.end
  //     var filters = this.state.filters

  //     let request = {
  //       "id": empId,
  //       "start": start,
  //       "end": end,
  //       "filters": filters,

  //     }

  //     let requestOptions = {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Authorization": "Bearer " + token
  //       },
  //       body: JSON.stringify({
  //         "origin": origin,
  //         "destination": destination,
  //         "date": tripDate,
  //         "arrival": 1,
  //         "mode": mode,
  //         "employee_id": employee_id,
  //       }),
  //     }

  //     var response = await fetch(this.state.endPoint + "trip_lean", requestOptions);

  //     if (!response.ok && response.type === 'cors') {
  //       await this.setState({ errorHappened: true })
  //       await this.setState({ errorMsg: 'No hemos podido crear este viaje' })
  //       this.setState({ loading: false })
  //     } else {

  //       await this.search(empId, 0, 0, {}, true);

  //       await this.setState({ loading: false })
  //     }

  //   } catch (err) {

  //     await this.setState({ errorHappened: true })
  //     await this.setState({ errorMsg: 'Por favor, revisa los datos' })

  //   }
  // }

  async createTrip(client, masa, time, amount7, verifier, origin, destination, tripDate, dontUses, minPay) {
    // var minPay = parseInt(minPay);
    // var amount7 = parseInt(amount7);

    try {
      this.setState({ somethingSearched: 0 });

      // if (minPay > amount7) {
      //   await this.setState({ errorHappened: true });
      //   await this.setState({ errorMsg: "El mínimo para el transportista no puede ser superior al precio" });
      // } else {
      await this.setState({ loading: true });


      // if (this.state.noWallet == true) {
      // get current date
      let today = new Date();
      // get number of days between today and 01/01/2022
      var days = Math.round((today - new Date(2022, 0, 1, 0,0,0)) / 86400000);
      var tripDatePlus = days + tripDate;

      // var bufferDayPlus = days + bufferDay;
      // var payDayPlus = days + payDay;
      let token = this.state.userToken;

      let employee_user_id = 0

      if (this.state.myEmployee && Object.keys(this.state.myEmployee).length > 0) {
     
        employee_user_id = this.state.myEmployee.user_id
      }


      let requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
        body: JSON.stringify({
          origin: origin,
          destination: destination,
          date: tripDatePlus,
          creationDay: days,
          extRef: client,
          bufferDay: masa,
          payDay: time["value"],
          maxAmount: 0,
          minAmount: 0,
          employee_user_id: employee_user_id,
        }),
      };

      var response = await fetch(this.state.endPoint + "trip", requestOptions);


      var responseClean = await response.json();
      if (responseClean === "minAmount must be less than maxAmount") {
        await this.setState({ errorHappened: true });
        await this.setState({ errorMsg: "El mínimo para el transportista no puede ser superior al precio" });
      } else {
        if (responseClean === "The order of the dates is not correct") {
          await this.setState({ errorHappened: true });
          await this.setState({ errorMsg: "El orden de las fechas no es correcto" });
        } else {
          //await this.getMainID()
          //await this.search(responseClean)
        }
      }
      // } else {
      //   await this.state.traxainDapp.methods
      //     .createTrip(stringRef, bufferDay, payDay, amount7, verifier)
      //     .send({ from: this.state.account })
      //     .on("transactionHash", (hash) => {});

      //   let traxainTokenBalance2 = await this.state.traxainToken.methods.balanceOf(this.state.account).call();
      //   await this.setState({ traxainTokenBalance: traxainTokenBalance2.toString() });
      //   let numberTrip = await this.state.traxainDapp.methods.mainID().call();
      //   await this.setState({ numberTrip: numberTrip });
      // }
      /* if (this.state.myEmployee ) {
        await this.search(this.state.myEmployee.id, 0, 0, {}, true);
        this.setState({ somethingSearched: 2 });
      }else{
        await this.tripsAndClients(true, true)
      } */
     

        const employeeId = this.state.myEmployee ? this.state.myEmployee.id : 0;
        
        if (employee_user_id !== 0) {
            await this.search(employeeId, 0, 0, {}, true);
            this.setState({ somethingSearched: 2 });
            
        } else {
            await this.tripsAndClients(true, true);
        }
      
      this.setState({ loading: false });
      // }
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Error a crear un viaje" });

    }
  }

  async askNewPass() {
    try {
      this.setState({ newPassAsked: true });
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error (1)" });
    }
  }


  toggleIsReloadTrips = async (value) => {
    await this.setState({ isReloadTrips: value });
  };

  toggleIsReloadActivities = async (value) => {
    await this.setState({ isReloadActivities: value });
  };

  toggleIsReloadDayConsult = async (value) => {
    await this.setState({ isReloadDayConsult: value });
  };

  async toggleShowHolidays() {
    this.setState(
      (prevState) => ({
        isToggleShowHolidays: !prevState.isToggleShowHolidays,
        isToggleCreateTrip: !prevState.isToggleShowHolidays ? false : prevState.isToggleCreateTrip,
      }),
      () => {
        //this.actualizarEstadoYAlmacenar();
        //this.actualizarEstadoYAlmacenarEmployees();
        this.showHolidays();
      }
    );
  }

  async toggleCreateTrip() {
    this.setState(
      (prevState) => ({
        isToggleCreateTrip: !prevState.isToggleCreateTrip,
        isToggleShowHolidays: !prevState.isToggleCreateTrip ? false : prevState.isToggleShowHolidays,
      }),
      () => {
        //this.actualizarEstadoYAlmacenar();
        //this.actualizarEstadoYAlmacenarEmployees();
        this.showCreateTripFields();
      }
    );
  }


  async getOldPass(oldPassword) {
    try {
      const CryptoJS = require("crypto-js");
      // variables que corresponden a Salt
      const salt = "farm1990M0O";
      const salt1 = "f1nd1ngn3m0";
      // se utilizó una variable "let" para poder reutilizarla dentro del código
      // Se añade salt a la contraseña y se cifra con SHA256 y se pasa a String
      let hashPassword = CryptoJS.SHA256(salt1 + oldPassword + salt).toString();
      let token = this.state.userToken;

      let requestOptions = await {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          a: hashPassword,
        }),
      };

      var response = await fetch(this.state.endPoint + "get_old_pass/", requestOptions).then((response) => response.json());
      if (response === "success") {
        return true;
      } else {
        let requestOptions = await {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            a: oldPassword,
          }),
        };

        response = await fetch(this.state.endPoint + "get_old_pass/", requestOptions).then((response) => response.json());

        if (response === "success") {
          return true;
        } else {
          return false;
        }
      }
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error (2)" });
    }
  }

  async assignNewPass(password1) {
    try {
      let token = this.state.userToken;

      const CryptoJS = require("crypto-js");
      const salt = "farm1990M0O";
      const salt1 = "f1nd1ngn3m0";
      const hashPassword = CryptoJS.SHA256(salt1 + password1 + salt).toString();

      let requestOptions = await {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          a: hashPassword,
        }),
      };

      var response = await fetch(this.state.endPoint + "change_pass/", requestOptions).then((response) => response.json());

      if (!response.ok && response.type === "cors") {
        await this.setState({ errorHappened: true });
        await this.setState({ errorMsg: "Por favor, revisa que las contraseñas sean iguales y que tengas más de 8 caracteres" });
      }

      if (response === "success") {
        if (Math.random()>0.5){
          setTimeout(() => {
            window.location.href = 'https://drive-team.es';
          }, 100)
        }else{
          setTimeout(() => {
            window.location.href = 'https://www.drive-team.es';
          }, 100)
  
        }
      }
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error (2)" });
    }
  }

  async editContinentalCredentials(account, password, source_code, source_password) {
    try {
      var token = this.state.userToken;

      let requestOptions = await {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          account: account,
          password: password,
          source_code: source_code,
          source_password: source_password,
        }),
      };

      var response = await fetch(this.state.endPoint + "edit-cont-details/", requestOptions).then((response) => response.json());
      await this.onClose();
      if (response === "none") {
        await this.setState({ errorHappened: true });
        await this.setState({ errorMsg: "Tus credenciales no funcionan, vuelve a probar" });
      }
      setTimeout(() => window.location.reload(), 100);
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error (3)" });
    }
  }

  async editRole(role) {
    try {
      var token = this.state.userToken;
      let requestOptions = await {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          a: role,
        }),
      };

      var response = await fetch(this.state.endPoint + "edit-user-role/", requestOptions);
      if (!response.ok && response.type === "cors") {
        await this.setState({ errorHappened: true });
        await this.setState({ errorMsg: "No hemos podido actualizar el rol de este usuario" });
      }
      await this.onClose();

      await this.setState({ errorHappened: true });
      await this.setState({
        errorMsg: "Aún no tenemos datos para ti. Contáctanos en traxain.com, escribiendo a ignacio@traxain o llamando al +34 638 26 96 33 (2)",
      });
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error (4)" });
    }
  }

  async editEmployeesSelected(employeesSelectedToShow, employeesSelected) {
    try {
      this.setState({ employeesSelectedToShow: employeesSelectedToShow });
      this.setState({ employeesSelected: employeesSelected });
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error (5)" });
    }
  }

  async editPeriodDetails(diet, comment) {
    await this.onClose();

    await this.setState({ somethingSearched: 0 });
    await this.setState({ loading: true });
    try {
      var token = this.state.userToken;

      var periodId = parseInt(await this.state.selectedPeriod.id);
      let empId = this.state.myEmployee.id;

      let dietInt = diet;
      if (isNaN(dietInt)) {
        dietInt = parseFloat(nat_feed_diet.replace(",", "."));
      }

      let start = this.state.start;
      let end = this.state.end;
      let filters = this.state.filters;
      let searchRequest = {
        id: empId,
        start: start,
        end: end,
        filters: filters,


        displayType: "dayConsult",
      };
      await this.setState({ loading: true });
      let requestOptions = await {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          a: empId,
          b: periodId,
          c: comment,

          d: diet,
          e: searchRequest,
        }),
      };
      var response = await fetch(this.state.endPoint + "edit_diet/", requestOptions);
      if (response.type === "cors" && !response.ok) {
        await this.setState({ errorHappened: true });
        await this.setState({ errorMsg: "No hemos podido actualizar los detalles de esta actividad" });

        await this.setState({ loading: false });

      } else {
        let myEmployee = await response.json();

        await this.setState({ myEmployee: myEmployee });
        await this.setState({ showModalActivity: false });
        await this.setState({ somethingSearched: 2 });
        await this.setState({ loading: false });

      }
      if (this.state.isShowModalEmployeeAlpha) {
        this.toggleIsReloadDayConsult(true);
      }
      await this.setState({ loading: false });
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "No hemos podido editar esos detalles" });
      await this.setState({ loading: false });
    }
  }

  async comprobarEstado(status) {
    this.setState({ chargeStatusTGD: status });
    setTimeout(() => this.showCheckState(), 2000);
  }

  async processNumber(processId, numFiles) {
    await this.setState({ showCheckStateModal: true });
    await this.setState({ activeProcessId: processId });
    await this.setState({ chargeStatusTGD: 1 });
    await this.setState({ numberOfFiles: numFiles });
    this.showCheckState();
  }

  async showCheckState() {
    try {
      let numberOfFiles = this.state.numberOfFiles;
      let passedCalls = this.state.passedCalls;
      let timeToTimeOut = numberOfFiles * 8000;
      if (timeToTimeOut > 120000) {
        timeToTimeOut = 120000;
      }

      if (this.state.chargeStatusTGD < 100 && this.state.userToken != "null" && this.state.userToken != null) {
        var responseGood;
        let processId = await this.state.activeProcessId;
        await this.setState({ showCheckStateModal: true });
        let prevStatusAdvance = await this.state.chargeStatusTGD;

        let config = {
          headers: {
            "content-type": "multipart/json",
          },
        };

        var responseData;

        axios.post(this.state.endPoint + "process_started/" + processId.toString(), config).then((response) => {
          responseGood = response;
          responseData = response.data;

          if (responseData != null && responseData != undefined) {
            if (responseData > prevStatusAdvance) {
              this.setState({ reloadEmployees: true });
              this.setState({ chargeStatusTGD: responseData });
            }
          }
        });

        await this.setState({ passedCalls: passedCalls + 1 });

        if (this.state.chargeStatusTGD < 100) {
          setTimeout(() => this.showCheckState(), timeToTimeOut);
        }
      } else {
        this.setState({ showCheckStateModal: true });
      }
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "No hemos podido mostrar el proceso" });
    }
  }

  async editTripDetails(origin, destination, time, mode) {
    await this.onClose();
    await this.setState({ somethingSearched: 0 });

    try {
      let token = this.state.userToken;
      let tripId = parseInt(this.state.selectedTrip);
      let empId = this.state.myEmployee.id;

      // Carga spinner antes de la llamada al back
      await this.setState({ loading: true });
      let requestOptions = await {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          a: empId,
          b: tripId,
          c: origin,
          d: destination,
          e: time,
          f: mode,
        }),
      };


      await this.setState({ loading: true });

      var response = await fetch(this.state.endPoint + "edit_trip/", requestOptions);
      if (response.type === "cors" && !response.ok) {
        await this.setState({ errorHappened: true });
        await this.setState({ errorMsg: "No hemos podido actualizar los detalles de esta actividad" });
        await this.setState({ loading: false });
      } else {
        let myEmployee = await response.json();

        //await this.setState({ myEmployee: myEmployee });
        await this.setState({ showModalActivity: false });
        await this.setState({ somethingSearched: 2 });
        await this.search(empId, 0, 0, {}, true);
        if (this.state.isShowModalEmployeeAlpha) {
          this.toggleIsReloadTrips(true);
        }
        // Cierra spinner al terminar todo el proceso
        await this.setState({ loading: false });

      }
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "No hemos podido editar esos detalles" });
      await this.setState({ loading: false });
    }
  }

  async deleteTrip(selectedTriptoDelete) {
    await this.onClose();
    await this.setState({ somethingSearched: 0 });

    try {
      let token = this.state.userToken;
      let tripId = parseInt(selectedTriptoDelete);
      let empId = this.state.myEmployee.id;

      let requestOptions = await {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          a: empId,
          b: tripId,
        }),
      };

      await this.setState({ loading: true });
      var response = await fetch(this.state.endPoint + "delete_trip/", requestOptions);

      if (response.type === "cors" && !response.ok) {
        await this.setState({ errorHappened: true });
        await this.setState({ errorMsg: "No hemos podido actualizar los detalles de esta actividad" });
        await this.setState({ loading: false });
      } else {
        let myEmployee = await response.json();
        await this.setState({ myEmployee: myEmployee });
        await this.setState({ showModalActivity: false });
        await this.setState({ somethingSearched: 2 });
        await this.setState({ loading: false });
        await this.search(empId, 0, 0, {}, true);
        if (this.state.isShowModalEmployeeAlpha) {
          this.toggleIsReloadTrips(true);
        }
        //Mostrar mensaje
        await this.setState({ successMsg: "El viaje ha sido eliminado exitosamente." });

        // Ocultar el mensaje después de 3 segundos
        setTimeout(() => {
          this.setState({ successMsg: "" });
        }, 3000); // 3000 milisegundos = 3 segundos
    

      }
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "No hemos podido editar esos detalles" });
      await this.setState({ loading: false });
    }
  }


  async hideTrip(selectedTriptoHide, fromTrips = false) {
    

    try {
      let token = this.state.userToken;
      let tripId = parseInt(selectedTriptoHide);
      let requestOptions = await {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          a: 0,
          b: tripId,
        }),
      };

      await this.setState({ loading: true });

      var response = await fetch((await this.state.endPoint) + "hide_trip/", requestOptions).then((response) => response.json());

      if (response.type === "cors" && !response.ok) {
        await this.setState({ errorHappened: true });
        await this.setState({ errorMsg: "No hemos podido actualizar los detalles de esta actividad" });
      } else if (response === "forbidden") {
        await this.setState({ errorHappened: true });
        await this.setState({ errorMsg: "No se puede borrar un viaje que no has creado" });
      } else if (response === "signed") {
        await this.setState({ errorHappened: true });
        await this.setState({ errorMsg: "No se puede borrar un viaje firmado" });
      } else if (response === "delete") {
        await this.setState({ showModalActivity: false });
        let employee = this.state.myEmployee;
        await this.setState({ errorHappened: true });
        await this.setState({ errorMsg: "Elemento borrado correctamente" });
       
        if (fromTrips) {
          await this.tripsAndClients(true, true, this.state.filter_trip_by_client);
          await this.setState({ showTripsTotal: true });

          await this.setState({ loading: false });
          await this.setState({ somethingSearched: 3 });
          await this.setState({ showTripsTotal: true });
          await this.setState({ somethingSearched: 3 });
      
          
        }else{
          await this.search(employee.id, 0, 0, {}, true);}
          await this.setState({ somethingSearched: 2 });
      }
      
      await this.setState({ loading: false });
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "No hemos podido editar esos detalles" });
      await this.setState({ loading: false });
    }
  }

  async editActivityDetails(comments, place, mode, listToEdit = []) {
    await this.onClose();
    let modeg;

    await this.setState({ somethingSearched: 0 });
    try {
      var token = this.state.userToken;
      var id = parseInt(this.state.selectedActivity);
      let empId;
      try {
        empId = this.state.myEmployee.id;
      } catch {
        empId = this.state.selectedActivity["employee_id"];
      }


      let start = this.state.start;
      let end = this.state.end;
      let filters = this.state.filters;

      let modeGood;
      let placeGood;
      let commentsGood;
      if (mode) {
        modeGood = mode;
      } else {
        modeGood = "";
      }
      if (place) {
        placeGood = place;
      } else {
        placeGood = "";
      }
      if (comments) {
        commentsGood = comments;
      } else {
        commentsGood = "";
      }

      let searchRequest = {
        id: empId,
        start: start,
        end: end,
        filters: filters,
        listToEdit: listToEdit,
      };
      let requestOptions = await {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          id: id,
          modeGood: modeGood,
          comments: commentsGood,
          place: placeGood,
          searchRequest: searchRequest,
        }),
      };

      await this.setState({ loading: true });
      await this.setState({ reloadEmployees: true });

      var response = await fetch(this.state.endPoint + "edit-activity-details/", requestOptions);

      if (response.type === "cors" && !response.ok) {
        await this.setState({ errorHappened: true });
        await this.setState({ errorMsg: "No hemos podido actualizar los detalles de esta actividad" });
        await this.setState({ loading: false });
      } else {
        let myEmployee = await response.json();
        await this.setState({ myEmployee: myEmployee });
        await this.setState({ showModalActivity: false });
        await this.setState({ showSendSmsActivity: false });
        await this.setState({ somethingSearched: 2 });
        await this.setState({ loading: false });
        if (this.state.isShowModalEmployeeAlpha) {
          this.toggleIsReloadActivities(true);
        }
      }
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "No hemos podido editar esos detalles" });
      await this.setState({ loading: false });
    }
  }

  async editSendSms(comments) {
    await this.onClose();

    let modeg;

    await this.setState({ somethingSearched: 0 });
    try {
      var token = this.state.userToken;
      var id = parseInt(this.state.selectedActivity);
      let empId;
      try {
        empId = this.state.myEmployee.id;
      } catch {
        empId = this.state.selectedActivity["employee_id"];
      }

      let start = this.state.start;
      let end = this.state.end;
      let filters = this.state.filters;

      //let modeGood
      //let placeGood
      let commentsGood;
      // //if (mode){
      // //  modeGood = mode
      // }else{
      //   modeGood = ""
      // }
      // if (place){
      //   placeGood = place
      // }else{
      //   placeGood = ""
      // }
      if (comments) {
        commentsGood = comments;
      } else {
        commentsGood = "";
      }

      let searchRequest = {
        id: empId,
        start: start,
        end: end,
        filters: filters,
      };
      let requestOptions = await {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          id: id,
          //"modeGood": modeGood,
          comments: commentsGood,
          //"place": placeGood,
          searchRequest: searchRequest,
        }),
      };

      await this.setState({ loading: true });

      var response = await fetch(this.state.endPoint + "edit-activity-details/", requestOptions);

      if (response.type === "cors" && !response.ok) {
        await this.setState({ errorHappened: true });
        await this.setState({ errorMsg: "No hemos podido enviar el mensaje" });
        await this.setState({ loading: false });
      } else {
        let myEmployee = await response.json();

        await this.setState({ myEmployee: myEmployee });
        await this.setState({ showSendSmsActivity: false });
        await this.setState({ somethingSearched: 2 });
        await this.setState({ loading: false });
      }
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "No hemos podido editar esos detalles" });
      await this.setState({ loading: false });
    }
  }

  async showModalActivity(selectedActivity) {
    try {
      await this.setState({ showModalActivity: true });
      await this.setState({ selectedActivity: selectedActivity["id"] });
      await this.setState({ selectedActivityDict: selectedActivity });

      if (selectedActivity["datails"]) {
        await this.setState({ defaultComments: selectedActivity["datails"][0] });
        await this.setState({ defaultPlace: selectedActivity["datails"][1] });
        await this.setState({ defaultIdentifier: selectedActivity["datails"][2] });
      }
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error (61)" });

    }
  }

  async showSendSmsActivity(selectedActivity) {
    try {
      await this.setState({ showSendSmsActivity: true, selectedActivity: selectedActivity["id"], selectedActivityDict: selectedActivity });

      if (selectedActivity["datails"]) {
        await this.setState({ defaultComments: selectedActivity["datails"][0] });
      }
    } catch (err) {
      await this.setState({ errorHappened: true });

      await this.setState({ errorMsg: "Se ha producido un error (62)" });
    }
  }
  async showGraphic(start, end, employees = [], callGraph = true, chartType = "scatter") {
    try {
      await this.setState({ loading: true });
      var bodyJS = JSON.stringify({
        start: start,
        end: end,
        employees: employees,
      });
      const token = this.state.userToken;
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: bodyJS,
      };
      var response = "notCalled";
      if (callGraph) {
        response = await fetch(this.state.endPoint + "data_graphicB/", requestOptions).then((response) => response.json());
      }
      await this.setState({ dataGraphic: response });
      await this.setState({ showGraphic: true });
      await this.setState({ loading: false });
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error (6)" });
    }

  }

  async showEditTripFields(id, trip = {}) {
    try {
      // Cerrar el modal antes de actualizar el estado
      this.setState({ showTripModal: false });

      // Actualizar el estado con el trip proporcionado

      if (Object.keys(trip).length > 0 &&  this.state.tripsTotal) {

        // Actualizar el trip específico dentro de myEmployee.trips
        this.setState((tripsTotal) => {
          const updatedTrips = this.state.tripsTotal.map((t) => (t.id === id ? { ...t, ...trip } : t));

          return {
            tripsTotal: updatedTrips,
            selectedTrip: id, // Actualizar el trip seleccionado
            showTripModal: true, // Volver a abrir el modal
          };
        }
        
        );
      }

      // Verificar si se proporcionó un trip para actualizar
      if (Object.keys(trip).length > 0 &&  this.state.prevState &&  this.state.prevState.myEmployee && this.state.prevState.myEmployee.trips) {

        // Actualizar el trip específico dentro de myEmployee.trips
        this.setState((prevState) => {
          const updatedTrips = prevState.myEmployee.trips.map((t) => (t.id === id ? { ...t, ...trip } : t));

          return {
            myEmployee: {
              ...prevState.myEmployee,
              trips: updatedTrips,
            },
            selectedTrip: id, // Actualizar el trip seleccionado
            showTripModal: true, // Volver a abrir el modal
          };
        }
        
        );
      } else {
        // Si no se proporcionó un trip para actualizar, simplemente abrir el modal
        this.setState({
          selectedTrip: id,
          showTripModal: true,
        });
      }
      window.scrollTo(0, 0);
    } catch (err) {
      // Manejar errores
      this.setState({
        errorHappened: true,
        errorMsg: "Se ha producido un error (63)",
      });
    }
  }

  // async showEditTripFields(selectedTrip) {
  //   try {
  //     await this.setState({ selectedTrip: selectedTrip["id"] });
  //     await this.setState({ originTrip: selectedTrip["city1"] });
  //     await this.setState({ destinationTrip: selectedTrip["city2"] });
  //     await this.setState({ dateTrip: selectedTrip["date"] });
  //     await this.setState({ modeTrip: selectedTrip["mode"] });
  //     await this.setState({ showTripModal: true });
  //   } catch (err) {
  //     await this.setState({ errorHappened: true });
  //     await this.setState({ errorMsg: "Se ha producido un error (6)" });
  //   }
  // }


  async showModalPeriod(selectedPeriod) {
    try {
      await this.setState({ selectedPeriod: selectedPeriod });
      await this.setState({ showPeriodModal: true });
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error (64)" });

    }
  }

  async editPeriodsListInit(init) {
    try {
      await this.setState({ periodsListInit: init });
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error (24)" });
    }
  }

  async onCloseNav() {
    try {
      await this.setState({ showNavigatorModal: false });
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error al cerrar el componente navegador" });
    }

  }

  async onCloseListAlpha() {
    try {
      await this.setState({ isShowModalEmployeeAlpha: false });
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error al cerrar la lista de empleados" });
    }
  }

  async onCloseLog() {
    try {
      await this.setState({ showLoginModalVar: false });
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error al cerrar el componente Login" });
    }
  }

  async onCloseReg() {
    try {
      await this.setState({ showRegisterModalVar: false });
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error al cerrar el componente registro" });
    }
  }

  async onClose() {
    try {
      await this.setState({ showModalActivity: false });

      await this.setState({ showSendSmsActivity: false });

      await this.setState({ showGraphic: false });

      await this.setState({ showPeriodModal: false });

      await this.setState({ showLastModal: false });

      await this.setState({ askTacProvider: false });

      await this.setState({ askContCred: false });

      await this.setState({ newPassAsked: false });
      await this.setState({ defaultComments: "" });
      await this.setState({ defaultPlace: "" });
      await this.setState({ defaultIdentifier: "" });

      await this.setState({ showTripModal: false });
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error (7)" });
    }
  }

  async editEmployeeListInit(init) {
    try {
      await this.setState({ listInit: init });
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error (9)" });
    }
  }

  async editActivityListInit(init) {
    try {
      await this.setState({ activityListInit: init });
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error (10)" });
    }
    // (this.state.activityListInit)
  }

  async editTripsListInit(init) {
    try {
      await this.setState({ tripsListInit: init });
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error (11)" });
    }
  }

  async editClient(email, address, username){
    // try {
      var token = await this.state.userToken;


      let bodyJS = JSON.stringify({
        a: email,
        b: 0,
        c: address,
        d: username,
      })
      // Naira Imprimir el cliente antes de enviar la solicitud
      

      let requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
        body: bodyJS,
      };
      // Imprimir el endpoint para depuración

      let response = await fetch((await this.state.endPoint) + "edit-client/", requestOptions);
      // Imprimir la respuesta del servidor

      //await this.tripsAndClients(false, true)
       // Verificar si la respuesta es exitosa
       if (!response.ok) {
        const errorData = await response.json();
        await this.props.showError("Se ha producido un error al asignar el cliente");
    } else {
        await this.tripsAndClients(false, true);
    }
     

    // } catch (err) {
    //   await this.props.showError("Se ha producido un error al asignar el cliente");
    // }

  }

  async editClientsListInit(init) {
    try {
      
      await this.setState({ clientsListInit: init });
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error (11)" });
    }
  }

  async showCreationManager() {
    try {
      // await this.setState({ loading: true })

      this.setState({ wantToCreate: false });

      if (this.state.wantToCreateManager) {
        this.setState({ wantToCreateManager: false });
      } else {
        this.setState({ wantToCreateManager: true });
      }
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error al crear el gerente" });
    }
    // await this.setState({ loading: false })
  }

  async showCreationFields() {
    try {
      //      this.setState({ wantToCreateManager: false })

      if (this.state.wantToCreate) {
        this.setState({ wantToCreate: false });
      } else {
        await this.setState({ showCreateTripFields: false });
        await this.setState({ wantToCreateManager: false });
        await this.setState({ showHolidays: false });
        this.setState({ wantToCreate: true });
      }
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error (12)" });
    }
    //await this.setState({ loading: false })
  }

  async selectProduct(product) {
    try {
      await this.setState({ product: product });
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error (13)" });
    }
  }

  async getParams() {
    try {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      let start = urlParams.get("start");
      let end = urlParams.get("end");
      let filters = urlParams.get("filters");
      let tk = urlParams.get("tk");
      let listToEdit = urlParams.get("listToEdit");
      let empId = urlParams.get("empId");
      if (!tk) {
        tk = null;
      }
      if (!listToEdit) {
        listToEdit = null;
      }
      if (!start) {
        start = 0;
      }
      if (!empId) {
        empId = 0;
      }
      if (!end) {
        end = 0;
      }
      let noFilters;
      if (!filters) {
        filters = {};
        noFilters = true;
      }
      let response = {
        start: start,
        end: end,
        filters: filters,
        tk: tk,
        empId: empId,
        listToEdit: listToEdit,
      };

      if (start === 0 && end === 0 && noFilters) {
        return "nothing";
      } else {
        return response;
      }
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error (14)" });
    }
  }

  async sendMessage(id, start, end, text) {
    try {
          var token = this.state.userToken;
          let requestOptions;

          var response;
          var bodyJS;

          if (this.state.selectedActivityDict["duration"]) {
            let startDate = this.state.selectedActivityDict["date"];
            let endDate = this.state.selectedActivityDict["date"];
            let startNew = this.state.selectedActivityDict["start"] - 100;
            let endNew = this.state.selectedActivityDict["start"] + 100;

            bodyJS = JSON.stringify({
              id: id,
              medium: "sms",
              text: text,
              dates: [
                [startNew, startDate],
                [endNew, endDate],
              ],
            });
            if (token !== "" && token !== null && token !== "null") {
              requestOptions = await {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + token,
                },
                body: bodyJS,
              };
              console.log("Voy a intentar enviar el mensaje");

              response = await fetch(this.state.endPoint + "send-message/", requestOptions).then((response) => response.json());
              console.log(response);
              if (response !== true) {
                alert("Por favor, revisa el teléfono");
              }
            }
          } else {
            let idEmployee = this.state.selectedActivityDict["employee_id"];
            let timestamp1 = this.state.selectedActivityDict["start"] - 100;
            let timestamp2 = this.state.selectedActivityDict["term"] + 100;
            let errors_ids = this.state.selectedActivityDict["suspList"];
            bodyJS = JSON.stringify({
              id: idEmployee,
              medium: "sms",
              text: text,
              dates: [[timestamp1, new Date(timestamp1 * 1000)], [timestamp2, new Date(timestamp2 * 1000)], errors_ids],
            });
            if (token !== "" && token !== null && token !== "null") {
              requestOptions = await {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + token,
                },
                body: bodyJS,
              };

              response = await fetch(this.state.endPoint + "send-message-on-period/", requestOptions).then((response) => response.json());
            }


            

            if (response !== true) {
              alert("Revisa el teléfono");
            } else {
              alert("¡Mensaje enviado!");
            }
          }
          
          if (response){
            this.setState({showSendSmsActivity:false})

            //await this.search(id, 0, 0, {}, true);
            if (this.state.somethingSearched === 1){
            this.usersEmployeeCount(0, 0, "text", {})
          } else {
            await this.search(id, 0, 0, {}, true);
          }
            
            if (this.state.isShowModalEmployeeAlpha) {
              this.toggleIsReloadActivities(true);
            }

          }
         
    } catch (err) {
      console.log("HA SALTADO EL REVISA")
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Revisa el teléfono" });
    }
  }

  async selectAccessMode(_mode) {
    try {
      if (_mode === "Blockchain") {
        await this.loadWeb3();
        await this.loadBlockchainData();
      }
      if (_mode === "Conventional") {
      }
      await this.setState({ selectedMode: _mode });
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error (15)" });
    }
  }

  async errorStatus() {
    this.setState({ loading: true });

    if (this.state.errorHappened === true) {
      await this.setState({ errorHappened: false });
    } else {
      await this.setState({ errorHappened: true });
    }
    this.setState({ loading: false });
  }

  async search(empId, start, end, filters, force, displayTypeParam = "", test = false, showAlerts = true) {

    try {
    if (showAlerts === false) {
      showAlerts = this.setState({ filterAlerts: showAlerts });
    }

    var myEmployee;

    if (!test) {
      window.scrollTo(0, 0);
      await this.setState({ loading: true });
    }
    await this.setState({ somethingSearched: 0 });
    if (start === 0 && this.state.myEmployee.startString) {
      start = new Date(this.state.myEmployee.startString);
      start = start.getTime() / 1000 - 86400 * 30;
    }
    if (end === 0 && this.state.myEmployee.endString) {
      end = new Date(this.state.myEmployee.endString);
      end = end.getTime() / 1000;
    }

    var displayType;

    if (displayTypeParam === "") {
      displayType = await this.state.displayType;
    } else {
      displayType = displayTypeParam;
    }
    let employees = this.state.employees;

    let employeesSelected = await this.state.employeesSelected;

    let employeesSelectedLen = await employeesSelected.length;

    if (employees.length > 0 && force === false && employeesSelectedLen === 0 && 1 === 2) {
      for (var e in employees) {
        if (employees[e].id === empId) {
          myEmployee = employees[e];
          break; // If you want to break out of the loop once you've found a match
        }

      }
    } else {
      var token = this.state.userToken;
      let requestOptions;


      var bodyJS = JSON.stringify({
        id: empId,
        start: start,
        end: end,
        displayType: displayType,
        filters: filters,
      });
      var response;
      if (token !== "" && token !== null && token !== "null") {
        requestOptions = await {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: bodyJS,
        };
        response = await fetch(this.state.endPoint + "employee/", requestOptions).then((response) => response.json());
      } else if (test) {
        await this.setState({ showTestDetails: false });

        requestOptions = await {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id: empId,
            start: start,
            end: end,
            displayType: displayType,
            filters: filters,
          }),
        };
        response = await fetch(this.state.endPoint + "employeeTest/", requestOptions).then((response) => response.json());
      }
      if (!response.ok && response.type === "cors") {
        if (Math.random()>0.5){
          setTimeout(() => {
            window.location.href = 'https://drive-team.es';
          }, 10)
        }else{
          setTimeout(() => {
            window.location.href = 'https://www.drive-team.es';
          }, 10)
  
        }
      }
      if (response === "Employee not found") {
        if (Math.random()>0.5){
          setTimeout(() => {
            window.location.href = 'https://drive-team.es';
          }, 10)
        }else{
          setTimeout(() => {
            window.location.href = 'https://www.drive-team.es';
          }, 10)
  
        }
      
      } else {

      console.log(response);
        myEmployee = await response;
        let myEmployeeState = await this.state.myEmployee;
        if (myEmployeeState !== undefined && myEmployeeState !== null) {
          if (
            myEmployee.id !== myEmployeeState.id ||
            myEmployee.startString !== myEmployeeState.startString ||
            myEmployee.endString !== myEmployeeState.endString
          ) {
            this.setState({ estadosGuardados: [] });
          } else {
            const prevDiets = myEmployeeState.diets;
            const prevExtraHours = myEmployeeState.net_extra_hours;
            const prevEmpComplies = myEmployeeState.employee_complies;
            if (JSON.stringify(myEmployee.diets) === "{}") {
              myEmployee.diets = prevDiets;
            }
            if (myEmployee.net_extra_hours === 0) {
              myEmployee.net_extra_hours = prevExtraHours;
            }
            if (JSON.stringify(myEmployee.employee_complies) === "{}") {
              myEmployee.employee_complies = prevEmpComplies;
            }
            //Si el id de myemployee es igual a this.state.myemployee.id entonces buscar solo las partes que han cambiado
            //Revisar diets, netextrahours y employeecomplies
          }
        } else {
          this.setState({ estadosGuardados: [] });
        }
      //}
    }

      await this.setState({ myEmployee: myEmployee });

      this.setState({ start: start });
      this.setState({ end: end });
      this.setState({ filters: filters });

     }
      //await this.showCreationFields()
      await this.setState({ displayType: displayType });
      await setTimeout(() => this.setState({ somethingSearched: 2 }), 200);

      if (test) {
        this.setState({ showTestDetails: false })
        await this.setState({ loading: true });
        await setTimeout(() => this.setState({ showTestDetails: true }), 1000);
      }

      await this.setState({ loading: false });

      return myEmployee;
     } catch (err) {
      if (Math.random()>0.5){
        setTimeout(() => {
          window.location.href = 'https://drive-team.es';
        }, 2000)
      }else{
        setTimeout(() => {
          window.location.href = 'https://www.drive-team.es';
        }, 2000)

      }
    }

  }

  async seslectDisplayMode(displayType) {
    //try {
      let prevdisplayType = this.state.displayType;
      await this.setState({ showCreateTripFields: false });
      await this.setState({ showHolidays: false });

      let userRole = this.state.userRole;
      await this.setState({ displayType: displayType });
      //await this.setState({ userRole: "provisional " });
      const myEmployee = await this.state.myEmployee;
      console.log(myEmployee);
      if (myEmployee) {
        let employeeId = this.state.myEmployee.id;
        let start = this.state.myEmployee.startString / 1000;
        let end = this.state.myEmployee.endString / 1000;
        let filtersDiets = { graphs: "graphs" };
        let filters = { label: "Semanas", value: "weekReports" };

        await this.setState({ somethingSearched: 0 });
        if (!this.state.estadosGuardados.includes(displayType)) {
          this.setState((prevState) => ({
            estadosGuardados: [...prevState.estadosGuardados, prevdisplayType],
          }));

          console.log(displayType)
          console.log(this.state.estadosGuardados)

          if (["text", "list", "trips","co2"].includes(displayType)) {
            if (
              !this.state.estadosGuardados.includes("text") &&
              !this.state.estadosGuardados.includes("list") &&
              !this.state.estadosGuardados.includes("co2")
            ) {
              await this.search(employeeId, start, end, filtersDiets, true);
            }
          }

          if (["periods", "dayConsult"].includes(displayType)) {
            if (!this.state.estadosGuardados.includes("periods") && !this.state.estadosGuardados.includes("dayConsult")) {
              await this.search(employeeId, start, end, filters, true);
            }
          }
        }

      }
      await this.setState({ somethingSearched: 2 });
      if (this.state.isShowModalEmployeeAlpha) {
        if (this.state.isToggleShowHolidays) {
          this.setState({ isToggleShowHolidays: false });
        } else if (this.state.isToggleCreateTrip) {
          this.setState({ isToggleCreateTrip: false });
        }
      }
      //await this.setState({ userRole: userRole });
    // } catch (err) {
    //   await this.setState({ errorHappened: true });
    //   await this.setState({ errorMsg: "Se ha producido un error (16)" });
    // }
  }

  async showCreateTripFields() {
    try {
      window.scrollTo(0, 0);


      let showCreateTripFields = this.state.showCreateTripFields;

      if (showCreateTripFields === true) {
        await this.setState({ showCreateTripFields: false });
      } else {
        await this.setState({ wantToCreate: false });
        await this.setState({ wantToCreateManager: false });
        await this.setState({ showHolidays: false });
        await this.setState({ showCreateTripFields: true });
      }
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error (18)" });
    }
  }

  async showHolidays() {
    try {

      //window.scrollTo(0, 0);
      //let showHolidays = this.state.showHolidays;

      if (this.state.showHolidays === true) {
        await this.setState({ showHolidays: false });
      } else {
        await this.setState({ showCreateTripFields: false });
        await this.setState({ wantToCreate: false });
        await this.setState({ wantToCreateManager: false });
        await this.setState({ showHolidays: true });
      }
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error (18)" });
    }
  }

  async selectANDirecto() {
    try {
      let showANDirecto = this.state.showANDirecto;

      if (showANDirecto) {
        await this.setState({ showANDirecto: false });
      } else {
        await this.setState({ showANDirecto: true });
      }
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error (199)" });
    }
  }

  async showError(message = "Se ha producido un error no especificado") {
    try {
      await this.setState({ errorHappened: true });

      await this.setState({ errorMsg: message });

    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un problema al mostrar un mensaje de error general" });
    }
  }

  async createManager(mail, password, username) {
    try {
      await this.setState({ loading: true });
      await this.setState({ somethingSearched: 0 });

      var token = this.state.userToken;

      let body = JSON.stringify({
        email: mail,
        is_active: true,
        client_reference: username,
        hashed_password: password,
      });

      let requestOptions = await {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: body,
      };

      var response = await fetch(this.state.endPoint + "create_manager", requestOptions);

      if (!response.ok && response.type === "cors") {
        this.setState({ loading: false });
        await this.setState({ errorHappened: true });
        await this.setState({ errorMsg: "No hemos podido crear este manager" });
      } else {
        this.usersManagerCount(0, 0, "text", {});
        this.setState({ loading: false });
      }

      window.scrollTo(0, 0);
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido creando el gerente" });
    }
  }

  async employees_manager(mail) {
    try {
      await this.setState({ showCreateTripFields: false });
      await this.setState({ showHolidays: false });
      this.setState({ loading: true });
      this.setState({ wantToCreate: true });
      this.setState({ wantToCreateManager: false });
      this.setState({ showListManager: false });
      this.setState({ somethingSearched: 0 });
      this.setState({ showANDirecto: false });

      let token = await this.state.userToken;

      let requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          id: 0,
          start: 0,
          end: 0,
          displayType: mail,
          filters: {},
        }),
      };

      let response = await fetch(this.state.endPoint + "employees_manager/", requestOptions).then((response) => response.json());

      if (!response.ok && response.type === "cors") {
        await this.setState({ errorHappened: true });
        await this.setState({ errorMsg: "No hemos encontrado los empleados de este gerente" });
      }

      let employees = JSON.parse(JSON.stringify(response));

      await this.setState({
        employeesList: employees,
        employees: employees,
      });

      this.setState({ dontCall: true });
      this.setState({ wantToCreate: false });
      this.setState({ wantToCreateManager: false });
      this.setState({ somethingSearched: 1 });
      this.setState({ loading: false });

      this.setState({ showList: true });
      return employees;
    } catch (err) {
      this.setState({ wantToCreate: false });
      this.setState({ wantToCreateManager: false });
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "No hemos encontrado los empleados de este gerente" });
      this.setState({ loading: false });
    }
  }


 async createEmployeeAlpha(employeeName, employeeApellidos, identificador, cardNumber, baseSalary, weekly_hours, phone, mail) {

    try {
      await this.setState({ loading: true });
      await this.setState({ somethingSearched: 0 });

      var token = this.state.userToken;
      // convert base salary into integer
      var employeeName1 = await employeeName;
      var employeeApellidos1 = await employeeApellidos;
      var identificador1 = await identificador;
      var cardNumber1 = await cardNumber;
      var baseSalaryInt1 = await parseInt(baseSalary * 1000);
      var weekly_hours1 = await parseInt(weekly_hours * 1000);

      var phone1 = await phone;
      var mail1 = await mail;


      let empId;
      let start;
      let end;
      let filters;

      if (this.state.myEmployee) {
        empId = this.state.myEmployee.id;
        start = this.state.start;
        end = this.state.end;
        filters = this.state.filters;
      } else {
        (empId = ""), (start = 0), (end = 0), (filters = {});
      }

      let searchRequest = {
        id: empId,
        start: start,
        end: end,
        filters: filters,
      };
      let body = JSON.stringify({
        employeeName: employeeName1,
        employeeApellidos: employeeApellidos1,
        identificador: identificador1,
        cardNumber: cardNumber1,
        baseSalary: baseSalaryInt1,
        weekly_hours: weekly_hours1,

        phone: phone1,
        mail: mail1,
        searchRequest: searchRequest,
      });

      let requestOptions = await {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: body,
      };

      var response = await fetch(this.state.endPoint + "create_employee", requestOptions);

      if (!response.ok && response.type === "cors") {
        this.setState({ loading: false });
        await this.setState({ errorHappened: true });
        await this.setState({ errorMsg: "No hemos podido crear este usario, revisa el correo" });
      } else {
        await this.usersEmployeeCount(0, 0, "text", {});

        this.setState({ loading: false });
      }
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error (19); Error al crear empleado." });
    }
  }


  async createEmployee(
    employeeName,
    employeeApellidos,
    identificador,
    cardNumber,
    baseSalary,
    weekly_hours,
    nat_feed_diet,
    nat_sleep_diet,
    int_feed_diet,
    int_sleep_diet,
    country,
    region,
    phone,
    mail,
    nat_complete_diet,
    nat_desayuno_diet,
    nat_tarde_diet,
    nat_tarde_cama_diet,
    nat_cena_diet,
    nat_km_diet,
    int_complete_diet,
    int_desayuno_diet,
    int_tarde_diet,
    int_tarde_cama_diet,
    int_cena_diet,
    int_km_diet,
    festive_comp,
    weekend_comp
  ) {
    window.scrollTo(0, 0);
    try {
      await this.setState({ loading: true });
      await this.setState({ somethingSearched: 0 });

      var token = this.state.userToken;
      // convert base salary into integer
      var employeeName1 = await employeeName;
      var employeeApellidos1 = await employeeApellidos;
      var identificador1 = await identificador;
      var cardNumber1 = await cardNumber;
      var baseSalaryInt1 = await parseInt(baseSalary * 1000);
      var weekly_hours1 = await parseInt(weekly_hours * 1000);
      var nat_feed_diet1 = await parseInt(nat_feed_diet * 1000);
      var nat_sleep_diet1 = await parseInt(nat_sleep_diet * 1000);
      var int_feed_diet1 = await parseInt(int_feed_diet * 1000);
      var int_sleep_diet1 = await parseInt(int_sleep_diet * 1000);
      var country1 = await country;
      var region1 = await region;
      var phone1 = await phone;
      var mail1 = await mail;
      var nat_complete_diet = await parseInt(nat_complete_diet * 1000);
      var nat_desayuno_diet = await parseInt(nat_desayuno_diet * 1000);
      var nat_tarde_diet = await parseInt(nat_tarde_diet * 1000);
      var nat_tarde_cama_diet = await parseInt(nat_tarde_cama_diet * 1000);
      var nat_cena_diet = await parseInt(nat_cena_diet * 1000);
      var nat_km_diet = await parseInt(nat_km_diet * 1000);
      var int_complete_diet = await parseInt(int_complete_diet * 1000);
      var int_desayuno_diet = await parseInt(int_desayuno_diet * 1000);
      var int_tarde_diet = await parseInt(int_tarde_diet * 1000);
      var int_tarde_cama_diet = await parseInt(int_tarde_cama_diet * 1000);
      var int_cena_diet = await parseInt(int_cena_diet * 1000);
      var int_km_diet = await parseInt(int_km_diet * 1000);
      var festive_comp = await parseInt(festive_comp * 1000);
      var weekend_comp = await parseInt(weekend_comp * 1000);

      let empId;
      let start;
      let end;
      let filters;

      if (this.state.myEmployee) {
        empId = this.state.myEmployee.id;
        start = this.state.start;
        end = this.state.end;
        filters = this.state.filters;
      } else {
        (empId = ""), (start = 0), (end = 0), (filters = {});
      }

      let searchRequest = {
        id: empId,
        start: start,
        end: end,
        filters: filters,
      };
      let body = JSON.stringify({
        employeeName: employeeName1,
        employeeApellidos: employeeApellidos1,
        identificador: identificador1,
        cardNumber: cardNumber1,
        baseSalary: baseSalaryInt1,
        weekly_hours: weekly_hours1,
        nat_feed_diet: nat_feed_diet1,
        nat_sleep_diet: nat_sleep_diet1,
        int_feed_diet: int_feed_diet1,
        int_sleep_diet: int_sleep_diet1,
        country: country1,
        region: region1,
        phone: phone1,
        mail: mail1,
        searchRequest: searchRequest,
        nat_complete_diet: nat_complete_diet,
        nat_desayuno_diet: nat_desayuno_diet,
        nat_tarde_diet: nat_tarde_diet,
        nat_tarde_cama_diet: nat_tarde_cama_diet,
        nat_cena_diet: nat_cena_diet,
        nat_km_diet: nat_km_diet,
        int_complete_diet: int_complete_diet,
        int_desayuno_diet: int_desayuno_diet,
        int_tarde_diet: int_tarde_diet,
        int_tarde_cama_diet: int_tarde_cama_diet,
        int_cena_diet: int_cena_diet,
        int_km_diet: int_km_diet,
        festive_comp: festive_comp,
        weekend_comp: weekend_comp,
      });

      let requestOptions = await {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: body,
      };

      var response = await fetch(this.state.endPoint + "create_employee", requestOptions);

      if (!response.ok && response.type === "cors") {
        this.setState({ loading: false });
        await this.setState({ errorHappened: true });
        await this.setState({ errorMsg: "No hemos podido crear este usario, revisa el correo" });
      } else {
        this.usersEmployeeCount(0, 0, "text", {});

        this.setState({ loading: false });
      }

      window.scrollTo(0, 0);
    } catch (err) {
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "Se ha producido un error (19)" });
    }
  }

  async usersManagerCount(start, end, displayType, filters) {
    try {
      await this.setState({ showCreateTripFields: false });
      await this.setState({ showHolidays: false });
      this.setState({ loading: true });
      this.setState({ wantToCreate: false });
      this.setState({ wantToCreateManager: false });
      this.setState({ somethingSearched: 0 });
      this.setState({ showANDirecto: false });

      let token = await this.state.userToken;

      let requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };

      let response = await fetch(this.state.endPoint + "managers/", requestOptions).then((response) => response.json());

      if (!response.ok && response.type === "cors") {
        await this.setState({ errorHappened: true });
        await this.setState({ errorMsg: "No hemos encontrado tus gerentes" });
      }

      let managers = JSON.parse(JSON.stringify(response));

      await this.setState({
        managersList: managers,
      });

      this.setState({ dontCall: true });
      this.setState({ wantToCreate: false });
      this.setState({ wantToCreateManager: false });
      this.setState({ loading: false });
      this.setState({ showList: false });
      this.setState({ showListManager: true });
    } catch (err) {
      this.setState({ wantToCreate: false });
      this.setState({ wantToCreateManager: false });
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "No hemos podido localizar tus gerentes" });
      this.setState({ loading: false });
    }
  }

  

  async tripsAndClients(showTrip = true, reload = false,  filter_trip_by_client = []) {
    this.setState({myEmployee: {}})
    this.setState({
      somethingSearched: 0,
      showTripsTotal: false,
      loading: true,
    });
    function arrayExists(arr, id, origin, destination) {
      return arr.some(function(item) {
        return item[0] === id && item[1] === origin && item[2] === destination;
      });
    }
    try {
      await this.setState({ showCreateTripFields: false });
      this.setState({ loading: true });
      this.setState({ showTrip: showTrip });
      this.setState({ filter_trip_by_client: filter_trip_by_client });
      this.setState({ wantToCreate: true });
      this.setState({ wantToCreateManager: false });
      this.setState({ showListManager: false });
      this.setState({ showANDirecto: false });

      let token = await this.state.userToken;
      //start is this.state.dateDesde as integet
      let start =  parseInt(this.state.dateDesde);
      let end =  parseInt(this.state.dateHasta);


      if (this.state.tripsTotal.length === 0 || reload === true) {

      let requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          id: 0,
          start: start,
          end: end,
          displayType: "",
          filters: {},
        }),
      };


      //variables
      

      let response = await fetch(this.state.endPoint + "user/trips", requestOptions).then((response) => response.json());

      let responseDict = JSON.parse(JSON.stringify(response));



      let trips = responseDict["trips"]

   

      let clients = responseDict["clients"];

      let absoluteCO2 = 0;
      let absoluteCO2Trips = 0;
      let addedTripsClients = []
      let addedTrips = []

      for (let i = 0; i < trips.length; i++) {
    
        // check if trips[i] already exists in addedTrips
        if (!arrayExists(addedTrips, trips[i]["id"],trips[i]["origin"], trips[i]["destination"])){
          addedTrips.push([trips[i]["id"],trips[i]["origin"], trips[i]["destination"]])
          absoluteCO2Trips += trips[i]["co2"];
        }
        

      }

      for (let i = 0; i < clients.length; i++) {
        let trips_ids = clients[i]["trips_ids"];
        // find all trips in trips with id in trips_ids and sum de trip.co2

        let total_co2 = 0;
        //console.log("usuario: ", clients[i]["email"])
        for (let j = 0; j < trips_ids.length; j++) {
          let trip_id = trips_ids[j];

          for (let k = 0; k < trips.length; k++) {
            if (trip_id === trips[k]["id"]) {
              total_co2 += trips[k]["co2"];
              if (!arrayExists(addedTripsClients, trips[k]["id"],trips[k]["origin"], trips[k]["destination"])){
                addedTripsClients.push([trips[k]["id"],trips[k]["origin"], trips[k]["destination"]])
                absoluteCO2 += trips[k]["co2"];
              }
              
            }
          }
        }
        clients[i]["total_co2"] = total_co2;
        
      }
      // order addedTripsClients by the elemnt [0] of each element
      addedTripsClients = addedTripsClients.sort((a, b) => a[0] - b[0]);
      
      addedTrips = addedTrips.sort((a, b) => a[0] - b[0]);

      this.setState({ tripsTotal: trips });
      this.setState({ tripsBackup: trips });
      this.setState({ clientsTotal: clients });
      this.setState({ absoluteCO2: Math.ceil(absoluteCO2 / 100000) });
      this.setState({ absoluteCO2Trips: Math.ceil(absoluteCO2Trips / 100000) });


    }else if(filter_trip_by_client.length > 0){
      
   

      let trips = this.state.tripsBackup;
      //console.log(trips)
      // get just the trips with id in filter_trip_by_client
      trips = trips.filter((trip) => filter_trip_by_client.includes(trip.id));
      this.setState({ tripsTotal: trips });
    }else{
      this.setState({ tripsTotal: this.state.tripsBackup });
    }


      // for (let i = 0; i < subs.length; i++) {
      //   let sub = subs[i];

      //   // if sub.trip_id in trips.id add the sub trip get the trip and sub. client to it
      //   for (let j = 0; j < trips.length; j++) {
      //     let trip = trips[j];
      //     if (sub.trip_id === trip.id) {
      //       // trip["subs"] = sub;
      //       clients.push(sub.client);
      //     }
      //   }
      // }
      

        this.setState({

          dontCall: true,
          wantToCreate: false,
          wantToCreateManager: false,
          somethingSearched: 3,
          loading: false,
          showTripsTotal: showTrip,
          showClientsTotal: !showTrip ,

        });

      


    } catch (err) {
      this.setState({ wantToCreate: false });
      this.setState({ wantToCreateManager: false });
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "No hemos podido localizar tus empleados" });
      this.setState({ loading: false });
    }
  }


  async usersEmployeeCount(start, end, displayType, filters) {
    try {
      await this.setState({ showCreateTripFields: false });
      await this.setState({ showHolidays: false });
      await this.setState({
        stringToSearch: "",
        employeesList: [],
        showList: false,
      });
      this.setState({ loading: true });
      this.setState({ myEmployee: {} });
      

      this.setState({ wantToCreate: true });
      this.setState({ wantToCreateManager: false });
      this.setState({ showListManager: false });
      this.setState({ somethingSearched: 0 });
      this.setState({ showANDirecto: false });

      let token = await this.state.userToken;

      let requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          id: 0,
          start: start,
          end: end,
          displayType: displayType,
          filters: filters
        }),
      };

      //variables
      let employees;
      let generalView;

      //este if hace una llamada al backend para recuperar los empleados si no hay empleados o reloadEmployees es true
      if (this.state.employeesList.length < 1 || this.state.reloadEmployees === true) {
        this.setState({ reloadEmployees: false });

        //llamada a employees, devuelve la lista de los empleados en JSON
        let response = await fetch(this.state.endPoint + "employees/", requestOptions).then((response) => response.json());


        //verifica si la respuesta es correcta
        if (!response.ok && response.type === "cors") {
          await this.setState({ errorHappened: true });
          await this.setState({ errorMsg: "No hemos encontrado tus empleados" });
        }

        //lee los JSON obtenidos
        employees = JSON.parse(JSON.stringify(response["employees"]));
        generalView = JSON.parse(JSON.stringify(response["generalView"]));

        //asigna valores al estado, entre ellos los empleados y el generalView leidos del JSON
        this.setState({
          employeesList: employees,
          employees: employees,
          generalView: generalView,
          dontCall: true,
          wantToCreate: false,
          wantToCreateManager: false,
          somethingSearched: 1,
          loading: false,
          displayType: displayType,
          showList: true, 
          myUser: {'mail':response['user_mail'],
          'user_id':response['user_id'] }
        });
      } else {
        //este else hace una llamada al backend para recuperar los empleados si hay empleados
        (employees = this.state.employeesList), (generalView = this.state.generalView);

        this.setState({
          employeesList: employees,
          employees: employees,
          generalView: generalView,
          dontCall: true,
          wantToCreate: false,
          wantToCreateManager: false,
          somethingSearched: 1,
          loading: false,
          displayType: displayType,
          showList: true,
          myEmployee: {}
        });

      }


      return employees;
    } catch (err) {
      this.setState({ wantToCreate: false });
      this.setState({ wantToCreateManager: false });
      await this.setState({ errorHappened: true });
      await this.setState({ errorMsg: "No hemos podido localizar tus empleados" });
      this.setState({ loading: false });
    }
  }

  async usersEmployeeLoad() {
    try {
      this.setState({ loading: true });
      this.setState({ wantToCreate: true });
      this.setState({ wantToCreateManager: false });

      let token = await this.state.userToken;

      let requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };

      let response = await fetch(this.state.endPoint + "employees-load/", requestOptions);

      if (!response.ok && response.type === "cors") {
        if (Math.random()>0.5){
          setTimeout(() => {
            window.location.href = 'https://drive-team.es';
          }, 2)
        }else{
          setTimeout(() => {
            window.location.href = 'https://www.drive-team.es';
          }, 2)
  
        }
      }


      let resposeArray = await JSON.parse(JSON.stringify(response));

      setTimeout(() => window.location.reload(), 100);
    } catch (err) {
      if (Math.random()>0.5){
        setTimeout(() => {
          window.location.href = 'https://drive-team.es';
        }, 2000)
      }else{
        setTimeout(() => {
          window.location.href = 'https://www.drive-team.es';
        }, 2000)

      }
    }
  }

  render() {


   try {



      //VARIBLES DE RENDER
      var modalamployeealpha = <div></div>
      var tripContent;
      let content = <div></div>;
      let table;
      let winHeight = window.innerHeight * 1.5;
      var sectionStyle;
      var loader = <div></div>;
      var error = <div></div>;
      let footerStyle;
      let footer = <div></div>;
      let pageFullyLoaded = this.state.pageFullyLoaded;
      let spacer = <div></div>;
      let employeesNow = this.state.employees;
      let testSection = <div></div>;

      if (winHeight > 1500) {
        winHeight = winHeight;
      } else {
        winHeight = 1500;
      }

      if (this.state.device === "desktop") {
        if (window.innerWidth > 1200) {
          sectionStyle = { width: "100%", height: "100%" };
        } else {
          sectionStyle = {
            width: "100%",
            height: "100%",
            backgroundImage: "url(" + this.state.fondoFinal + ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "bottom",
          };
        }
      } else {
        sectionStyle = {
          width: "100%",
          height: "1000px",
          backgroundImage: "url()",

          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "bottom",
        };
      }

    let navbar = <div>
      <Navbar
              showError={this.showError}
              account={this.state.account}
              device={this.state.device}
              product={this.state.product}
              selectProduct={this.selectProduct}
              showCreationFields={this.showCreationFields}
              usersEmployeeCount={this.usersEmployeeCount}
              usersEmployeeLoad={this.usersEmployeeLoad}
              userRole={this.state.userRole}
              askNewPass={this.askNewPass}
              selectANDirecto={this.selectANDirecto}
              showANDirecto={this.state.showANDirecto}
              showNavigatorModal={this.showNavigatorModal}
              showNavigatorModalHome={this.showNavigatorModalHome}
              showLoginModal={this.showLoginModal}
              showRegisterModal={this.showRegisterModal}
              winWidth={this.state.winWidth}
              winHeight={this.state.winHeight}
              volverAlPasado={this.volverAlPasado}
              regresoAlFuturo={this.regresoAlFuturo}
              stateHistory={this.state.stateHistory}
              stateFuture={this.state.stateFuture}
            />
            <br/><br/>
    </div>


    if (this.state.loading === true) {
      setTimeout(
        (loader = (
          <div style={{ textAlign: "center" }}>
            <CustomSpinner device={this.state.device} />
            <div
              className="position-fixed vw-100 vh-100 d-flex align-items-center justify-content-center"
              id="loader"
              style={{ fontSize: 24, color: "#18a100", animation: "infinite", willChange: "transform" }}
            ></div>
          </div>
        )),
        200
      );
    }

    if (this.state.errorHappened) {
      var error = <ErrorHappened errorStatus={this.errorStatus} errorMsg={this.state.errorMsg} />;
    }

    if (this.state.errorExpirated) {
      var error = <ErrorExpirated errorStatus={this.errorStatus} errorMsg={this.state.errorMsg} />;
    }


    if (this.state.userToken === "null" || this.state.userToken === null) {
      let JustToTesthtml = <div></div>;

      if (!this.state.loading || this.state.showTestDetails) {
        

        JustToTesthtml = (
          <JustToTest
            showTestDetails={this.state.showTestDetails}
            myEmployee={this.state.myEmployee}
            strStatus={this.state.strStatus}
            showModalActivity={this.showModalActivity}
            showSendSmsActivity={this.showSendSmsActivity}
            showGraphic={this.showGraphic}
            thisDeposited={this.state.thisDeposited}
            currentUser={this.state.currentUser}
            search={this.search}
            seslectDisplayMode={this.seslectDisplayMode}
            displayType={this.state.displayType}
            activityListInit={this.state.activityListInit}
            editActivityListInit={this.editActivityListInit}
            showCreationFields={this.showCreationFields}
            userRole={this.state.userRole}
            editTripsListInit={this.editTripsListInit}
            tripsListInit={this.state.tripsListInit}
            device={this.state.device}
            showCreateTripFields={this.showCreateTripFields}
            showHolidays={this.showHolidays}
            showError={this.showError}
            editPeriodsListInit={this.editPeriodsListInit}
            periodsListInit={this.state.periodsListInit}
            putLoading={this.putLoading}
            activeProcessId={this.state.activeProcessId}
            showCheckState={this.showCheckState}
            processNumber={this.processNumber}
            endPoint={this.state.endPoint}
            usersEmployeeCount={this.usersEmployeeCount}
            userToken={this.state.userToken}
            setEmployee={this.setEmployee}
          />
        );
      }
      if (this.state.device === "desktop") {
        testSection = (
          <div id="content" className="mt-3">
            <div className="card mb-4">
              <div className="card-body" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width: "100%" }}>


                {JustToTesthtml}
              </div>
            </div>
          </div>
        );

          content = 
            <table className='wid-100'>
              <table className='wid-100 he-100 ' style={{backgroundImage: "url(" + this.state.camionesFinal + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "top"}}>
                <br/><br/><br/><br/>
                <tbody className='wid-100'>
                  <tr className='wid-100'>
                    <td className='wid-5'>
                    </td>
                    <td className='wid-90 te-ali-cen fw-med'>
                      <h1 className='fw-med text-center' style={{ color: '#454444'}}>Drive-Team.es</h1>
                      <br/>
                      <h3 className='fw-med text-center' style={{ color: '#454444'}}>Saca provecho a los datos de tus tacógrafos. Traxain te ayuda usando los datos legalmente válidos del tacógrafo para calcular y controlar tus gastos en personal</h3>
                      <h2 className='text-center' style={{ color: "#457AF3" }}>+</h2>
                      <h3 className='fw-med text-center' style={{ color: '#454444'}}>Calcula las emisiones de CO2 y la intensidad de CO2 a través del tacógrafo según la carga, tu vehículo, los viajes en vacío y grupajes</h3>
                      <h2 className='text-center' style={{ color: "#457AF3" }}>+</h2>
                      <h3 className='fw-med text-center' style={{ color: '#454444'}}>Consulta el Dashboard de productividad de cada conductor</h3>
                      <h2 className='text-center' style={{ color: "#457AF3" }}>+</h2>
                      <h3 className='fw-med text-center' style={{ color: '#454444'}}>Calcula las dietas de alimentación y pernocta</h3>
                      <h2 className='text-center' style={{ color: "#457AF3" }}>+</h2>
                      <h3 className='fw-med text-center' style={{ color: '#454444'}}>Pronostica y calcula complementos de desplazamiento y nocturnidad</h3>
                      <h2 className='text-center' style={{ color: "#457AF3" }}>+</h2>
                      <h3 className='fw-med text-center' style={{ color: '#454444'}}>Detecta y aclara el comportamiento inusual del conductor</h3>
                      <h2 className='text-center' style={{ color: '#5A5A5A' }}></h2>
                      <br/><br/>
                      <h3 className='fw-med text-center' style={{ color: '#454444'}}>Regístrate sin compromiso abajo para empezar a probarlo.</h3>
                      <br/>
                      <h3 className='fw-med text-center' style={{ color: '#454444'}}>¿No sabes por dónde empezar? Consulta <a href="https://youtu.be/y5eT_VS2xco">nuestros videotutoriales.</a> Puedes ver más información en <a href="https://traxain.com/post/drive-team">Traxain.com</a>, contactarnos en <a href="mailto:ignacio@traxain.com"> ignacio@traxain.com </a>, o llamarnos al  <a href="tel:+34638269633">+34 638 26 96 33 </a> </h3>
                      <br/>
                      {/* //TODO cambiar el tamaño de este div */}
                      <div  className='bg-white'
                          onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
                          onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}>                      
                          <h3 className='text-center' style={{color: '#454444',fontWeight: 'medium',margin: 0, textShadow:"none"}}>
                          <i className="fas fa-arrow-down"></i>Pruébalo ahora sin necesidad de registrarte<i className="fas fa-arrow-down"></i>
                        </h3>
                      </div>                  
                      <br/>
                    </td>
                    <td className='wid-5'>
                    </td>
                  </tr>
                </tbody>
              <br/><br/>
              </table>

              <table className='wid-100' style={{backgroundColor: "#eeeff2" }}>
                <br/><br/>
                <tbody className='wid-100'>
                  <tr className='wid-100'>
                    <td className='wid-17'>
                    </td>
                    <td className='wid-66'>
                    </td>
                    <td className='wid-17'>
                    </td>
                  </tr>
                  <tr className='wid-100'>
                    <td className='wid-17'>
                    </td>
                    <td className='wid-66'>
                      {testSection}
                    </td>
                    <td className='wid-17'>
                    </td>
                  </tr>
                </tbody>
              </table>
            <br/><br/>

          </table>
        
          table = <div></div>
        } else {
          content =
            <table className='wid-100'>
              <table className="wid-100">
                <tr className="wid-100 h-50-px">  </tr>
              <tr className="wid-100" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}>
                <td className="wid-5"></td>
                
                <td className="wid-80 te-ali-cen fw-lig" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                <h1 className="fw-med" style={{ color: '#5A5A5A' }}>
                    Bienvenido a Drive-Team
                  </h1>
                  <br />
                  <h4 className="fw-lig" style={{ color: '#5A5A5A' }}>
                    Comprueba los datos de tu tacógrafo
                  </h4>
                  <h2 style={{ color: '#457AF3' }}>+ </h2>
                  <h4 className="fw-lig" style={{ color: '#5A5A5A' }}>
                    Verifica las horas extras que has realizado
                  </h4>
                  <h2 style={{ color: '#457AF3' }}>+ </h2>
                  <h4 className="fw-lig" style={{ color: '#5A5A5A' }}>
                    Calcula en base al convenio de tu provincia
                  </h4>
                  <br />
                  <br />
                  <div className="label">
                    <img src={truck} alt={fondoMovil} className="wid-100" />
                  </div>
                  <br />
                </td>
                <td className="" style={{ width: '5%' }}></td>
              </tr>
            </table>
            <table style={{ maxWidth: "100%" }} className="wid-100">
              <tbody style={{ maxWidth: "100%" }} className="wid-100">
                <br /><br />
                <tr style={{ maxWidth: "100%", display: 'flex', justifyContent: 'center' }}>
                  <td className="wid-10"></td>
                  <td className="wid-80" style={{ maxWidth: "80%", flex: 1, padding: '0 10px', wordWrap: 'break-word',display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'  }}>
                    {JustToTesthtml}
                  </td>
                  <td className="wid-10"></td>
                </tr>
              </tbody>
            </table>
            <br />

          </table>

        table = <div></div>;
      }
    } else {
      table = <div></div>;
      let actions;
      let showUpload;
      let endpoint = this.state.endPoint;
      let listContent;

      if (this.state.showCheckStateModal) {
        showUpload = (
          <div>
            <CheckState show={this.state.showCheckStateModal} onClose={this.onClose} chargeStatusTGD={this.state.chargeStatusTGD} />
          </div>
        );
      }
      // if (this.state.upLoadTGDManually === false || this.state.myEmployee === {}) {
        actions = (
          <div>
            <Actions
              userRole={this.state.userRole}
              device={this.state.device}
              tripsAndClients= {this.tripsAndClients}
              showCreationFields={this.showCreationFields}
              showCreationManager={this.showCreationManager}
              usersEmployeeCount={this.usersEmployeeCount}
              usersManagerCount={this.usersManagerCount}
              usersEmployeeLoad={this.usersEmployeeLoad}
              searchManual={this.searchManual}
              actualizarEstadoYAlmacenar={this.actualizarEstadoYAlmacenar}
              showGraphic={this.showGraphic}
              isFeatureEnabled={this.state.isFeatureEnabled}
              seleccionBotonAlpha={this.seleccionBotonAlpha}
              seleccionBoton={this.seleccionBoton}
              showModalEmployeeAlpha={this.showModalEmployeeAlpha}
            />
            <UploadTGDFile
              activeProcessId={this.state.activeProcessId}
              showCheckState={this.showCheckState}
              processNumber={this.processNumber}
              endPoint={this.state.endPoint}
              myEmployee={this.state.myEmployee}
              putLoading={this.putLoading}
              usersEmployeeCount={this.usersEmployeeCount}
              userToken={this.state.userToken}
              setEmployee={this.setEmployee}
            />
            <table nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width: "100%" }}>
              <tbody nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width: "100%" }}>
                <tr nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width: "100%", display: "flex", justifyContent: "center", alignContent: "center" }}>
                  <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width: "47%" }}>
                    <form
                      onSubmit={(event) => {
                        event.preventDefault();
                        this.dowloadActivityReport();
                      }}
                    >
                      <button
                        type="submit"
                        nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg"
                        style={{ width: "100%", fontSize: 16 }}
                        className="btn btn-primary btn-block btn-lg"
                      >
                        Reporte detallado
                      </button>
                    </form>
                  </td>
                  <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width: "2%" }}></td>
                  <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width: "47%" }}>
                    <form
                      onSubmit={(event) => {
                        event.preventDefault();
                        this.dowloadPeriodsReport();
                      }}
                    >
                      <button
                        type="submit"
                        nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg"
                        style={{ width: "100%", fontSize: 16 }}
                        className="btn btn-primary btn-block btn-lg"
                      >
                        Reporte por día
                      </button>
                    </form>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      // } else {
      //   actions = (
      //     <div>
      //       <form
      //         onSubmit={(event) => {
      //           event.preventDefault();
      //           this.actualizarEstadoYAlmacenar();
      //           this.usersEmployeeCount(0, 0, "text", {});
      //         }}
      //       >
      //         <button type="submit" style={{ width: "100%", fontSize: 16 }} className="btn btn-primary btn-block btn-lg">
      //           Volver a empleados
      //         </button>
      //       </form>
      //       <br />
      //       <br />
      //       <UploadTGDFile
      //         activeProcessId={this.state.activeProcessId}
      //         showCheckState={this.showCheckState}
      //         processNumber={this.processNumber}
      //         endPoint={this.state.endPoint}
      //         myEmployee={this.state.myEmployee}
      //         putLoading={this.putLoading}
      //         usersEmployeeCount={this.usersEmployeeCount}
      //         userToken={this.state.userToken}
      //         setEmployee={this.setEmployee}
      //       />
      //     </div>
      //   );
      // }
      if (this.state.userRole == "admin" || this.state.userRole == "manager") {
        if (this.state.wantToCreate === true) {
          listContent = (
            <div>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  this.activateSimulator();
                  this.props.actualizarEstadoYAlmacenar();
                }}
              >
                <br />
                <button
                  type="submit"
                  nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg"
                  style={{ width: "100%", fontSize: 16, height: "100%" }}
                  className="btn btn-primary btn-block btn-lg"
                >
                  Activar simulador
                </button>
              </form>
              <br />
              <Creation
                createEmployee={this.createEmployee}
                myEmployee={this.state.myEmployee}
                showError={this.showError}
                showSimulator={this.state.showSimulator}
              />
            </div>
          );
        } else if (this.state.wantToCreateManager) {
          listContent = (
            <div>
              <br />
              <CreationManager
                createManager={this.createManager}
                employees_manager={this.employees_manager}
                myEmployee={this.state.myEmployee}
                putLoading={this.putLoading}
                usersEmployeeCount={this.usersEmployeeCount}
                userToken={this.state.userToken}
                setEmployee={this.setEmployee}
              />          
              <table className='wid-100'>
                <tbody className='wid-100'>
                  <tr className='wid-100 d-p-flex j-c-cen ali-con-center'>
                    <td className='wid-47'>
                      <form  onSubmit={(event) => {
                        event.preventDefault()
                        this.dowloadActivityReport()
                      }}>                                  
                        <button type="submit" className="btn btn-primary btn-block btn-lg wid-100 fs-16">Reporte detallado</button>
                      </form>
                    </td>
                    <td className='wid-2'>                              
                    </td>
                    <td className='wid-47'>
                      <form  onSubmit={(event) => {
                        event.preventDefault()
                        this.dowloadPeriodsReport()
                      }}>
                        <button type="submit" className="btn btn-primary btn-block btn-lg wid-100 fs-16">Reporte por día</button>
                      </form>
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>
          );
        } else if (this.state.showCreateTripFields) {
          listContent = (
            <div>

              <TripCreation myEmployee={this.state.myEmployee} device={this.state.device} createTrip={this.createTrip} showError={this.showError} />
            </div>
          );
        } else if (this.state.showHolidays) {
          listContent = (
            <div>
              <HolidayEmployee
                device={this.state.device}
                showError={this.showError}

                endPoint={this.state.endPoint}

                userToken={this.state.userToken}
                myEmployee={this.state.myEmployee}
              />
            </div>
          );
        }
        if (this.state.userRole == "admin" || this.state.userRole == "manager") {
          if (this.state.wantToCreate === true) {
            listContent = (
              <div>
                <form onSubmit={(event) => {
                  event.preventDefault()
                  this.activateSimulator()
                  this.props.actualizarEstadoYAlmacenar()
                }}>
                <br/>
                  <button type="submit" className="btn btn-primary btn-block btn-lg wid-100 fs-16 he-100">

                    Activar simulador
                  </button>
                </form>
                <br />
                <Creation
                  createEmployee={this.createEmployee}
                  myEmployee={this.state.myEmployee}
                  showError={this.showError}
                  showSimulator={this.state.showSimulator}
                />
              </div>
            );
          } else if (this.state.wantToCreateManager) {
            listContent = (
              <div>
                <br />
                <CreationManager
                  createManager={this.createManager}
                  employees_manager={this.employees_manager}
                  myEmployee={this.state.myEmployee}
                  showError={this.showError}
                />
              </div>
            );
          } else if (this.state.showCreateTripFields) {
            listContent = (
              <div>
                <CreationCMR
                  myTrip={this.state.myTrip}
                  device={this.state.device}
                  thisTripID={this.state.thisTripID}
                  createTrip={this.createTrip}
                  showError={this.showError}
                  somethingSearched={this.state.somethingSearched}
                  wantToCreate={this.state.wantToCreate}
                  numberTrip={this.state.numberTrip}
                  noWallet={this.state.noWallet}
                  endPoint={this.state.endPoint}
                  createStripeAccount={this.createStripeAccount}
                  askCheckout={this.state.askCheckout}
                />
                {/* <TripCreation
                  myEmployee={this.state.myEmployee}
                  device={this.state.device}
                  createTrip={this.createTrip}
                  showError={this.showError}
                /> */}
              </div>
            );
          } else if (this.state.showHolidays) {
            listContent = (
              <div>
                <HolidayEmployee
                  device={this.state.device}
                  showError={this.showError}
                  endPoint={this.state.endPoint}
                  userToken={this.state.userToken}
                  myEmployee={this.state.myEmployee}
                />
              </div>
            );
          } else if (this.state.showList) {

          
              // modalamployeealpha = (<ModalEmployeeAlpha
              //   activityListInit={this.state.activityListInit}
              //   actualizarEstadoYAlmacenar={this.actualizarEstadoYAlmacenar}
              //   childFuture={this.state.childFuture}
              //   childHistory={this.state.childHistory}
              //   createEmployeeAlpha={this.createEmployeeAlpha}
              //   createTrip={this.createTrip}
              //   currentUser={this.state.currentUser}
              //   deleteTrip={this.deleteTrip}
              //   deshacerFiltrado={this.deshacerFiltrado}
              //   device={this.state.device}
              //   displayType={this.state.displayType}
              //   dowloadActivityReport={this.dowloadActivityReport}
              //   dowloadEmployeeReport={this.dowloadEmployeeReport}
              //   editActivityListInit={this.editActivityListInit}
              //   editEmployeeListInit={this.editEmployeeListInit}
              //   editPeriodsListInit={this.editPeriodsListInit}
              //   editTripsListInit={this.editTripsListInit}
              //   employees={this.state.employees}
              //   employeesList={this.state.employeesList}
              //   endPoint={this.state.endPoint}
              //   filterAlerts={this.state.filterAlerts}
              //   filterDiets={this.state.filterDiets}
              //   filteredActivities={this.state.filteredActivities}
              //   filters={this.state.filters}
              //   filtrarDietas={this.filtrarDietas}
              //   isFeatureEnabled={this.state.isFeatureEnabled}
              //   isLoading={this.state.isLoading}
              //   isShowCreateTripFields={this.state.showCreateTripFields}
              //   isShowHolidays={this.state.showHolidays}
              //   limpiarDietas={this.limpiarDietas}
              //   listInit={this.state.listInit}
              //   myEmployee={this.state.myEmployee}
              //   myTrip={this.state.myTrip}
              //   onCloseListAlpha={this.onCloseListAlpha}
              //   periodsListInit={this.state.periodsListInit}
              //   putFilterAlerts={this.putFilterAlerts}
              //   putFilterDiets={this.putFilterDiets}
              //   ref={this.childRefEmployee}
              //   ref2={this.childRefHandle}
              //   ref3={this.childRefDisplayAct}
              //   regresoAlFuturo={this.regresoAlFuturo}
              //   search={this.search}
              //   selectFilterAlerts={this.selectFilterAlerts}
              //   sendMessage={this.sendMessage}
              //   seslectDisplayMode={this.seslectDisplayMode}
              //   showError={this.showError}
              //   showModalActivity={this.showModalActivity}
              //   showModalEmployeeAlpha={this.showModalEmployeeAlpha}
              //   isShowModalEmployeeAlpha={this.state.isShowModalEmployeeAlpha}
              //   showModalPeriod={this.showModalPeriod}
              //   showCreateTripFields={this.showCreateTripFields}
              //   showCreationFields={this.showCreationFields}
              //   showEditTripFields={this.showEditTripFields}
              //   showGraphic={this.showGraphic}
              //   showHolidays={this.showHolidays}
              //   showSendSmsActivity={this.showSendSmsActivity}
              //   show={this.state.isShowModalEmployeeAlpha}
              //   somethingSearched={this.state.somethingSearched}
              //   stateFuture={this.state.stateFuture}
              //   stateHistory={this.state.stateHistory}
              //   strStatus={this.state.strStatus}
              //   thisDeposited={this.state.thisDeposited}
              //   thisTripID={this.state.thisTripID}
              //   tripsListInit={this.state.tripsListInit}
              //   TripCreation={this.state.TripCreation}
              //   userRole={this.state.userRole}
              //   userToken={this.state.userToken}
              //   volverAlPasado={this.volverAlPasado}
              //   wantToCreate={this.state.wantToCreate}
              //   loading={this.state.loading}
              //   isReloadTrips={this.state.isReloadTrips}
              //   toggleIsReloadTrips={this.toggleIsReloadTrips}
              //   isReloadActivities={this.state.isReloadActivities}
              //   toggleIsReloadActivities={this.toggleIsReloadActivities}
              //   isReloadDayConsult={this.state.isReloadDayConsult}
              //   toggleIsReloadDayConsult={this.toggleIsReloadDayConsult}
              //   isToggleShowHolidays={this.state.isToggleShowHolidays}
              //   isToggleCreateTrip={this.state.isToggleCreateTrip}
              //   toggleShowHolidays={this.toggleShowHolidays}
              //   toggleCreateTrip={this.toggleCreateTrip}
              // />)
              listContent = (
                <List
                  myTrip={this.state.myTrip}
                  thisTripID={this.state.thisTripID}
                  somethingSearched={this.state.somethingSearched}
                  wantToCreate={this.state.wantToCreate}
                  listInit={this.state.listInit}
                  device={this.state.device}
                  search={this.search}
                  editEmployeeListInit={this.editEmployeeListInit}
                  showCreationFields={this.showCreationFields}
                  showError={this.showError}
                  filters={this.state.filters}
                  myEmployee={this.state.myEmployee}
                  employeesList={this.state.employeesList}
                  actualizarEstadoYAlmacenar={this.actualizarEstadoYAlmacenar}
                />
              );
            
            
          } else if (this.state.showListManager) {
            listContent = (
              <ListManager
                listInit={this.state.listInit}
                device={this.state.device}
                search={this.search}
                employees_manager={this.employees_manager}
                editEmployeeListInit={this.editEmployeeListInit}
                showCreationFields={this.showCreationFields}
                managersList={this.state.managersList}
              />
            );
          } else {
            listContent = <CustomSpinner device={this.state.device} />;
          }
          if (this.state.somethingSearched === 2 && Object.keys(this.state.myEmployee).length > 0) {
            tripContent = 
              <div id="content" className="mt-3">
                <div className="card mb-4" >
                  <div className="card-body wid-100">

                    <EmployeeDetails
                      deleteTrip={this.deleteTrip}
                      filterAlerts={this.state.filterAlerts}
                      filterDiets={this.state.filterDiets}
                      filtrarDietas={this.filtrarDietas}
                      limpiarDietas={this.limpiarDietas}
                      deshacerFiltrado={this.deshacerFiltrado}
                      putFilterDiets={this.putFilterDiets}
                      putFilterAlerts={this.putFilterAlerts}
                      selectFilterAlerts={this.selectFilterAlerts}
                      filteredActivities={this.state.filteredActivities}
                      sendMessage={this.sendMessage}
                      showModalPeriod={this.showModalPeriod}
                      showEditTripFields={this.showEditTripFields}
                      myEmployee={this.state.myEmployee}
                      strStatus={this.state.strStatus}
                      showModalActivity={this.showModalActivity}
                      showSendSmsActivity={this.showSendSmsActivity}
                      showGraphic={this.showGraphic}
                      thisDeposited={this.state.thisDeposited}
                      currentUser={this.state.currentUser}
                      dowloadActivityReport={this.dowloadActivityReport}
                      search={this.search}
                      seslectDisplayMode={this.seslectDisplayMode}
                      displayType={this.state.displayType}
                      activityListInit={this.state.activityListInit}
                      editActivityListInit={this.editActivityListInit}
                      showCreationFields={this.showCreationFields}
                      userRole={this.state.userRole}
                      editTripsListInit={this.editTripsListInit}
                      tripsListInit={this.state.tripsListInit}
                      device={this.state.device}
                      showCreateTripFields={this.showCreateTripFields}
                      showHolidays={this.showHolidays}
                      showError={this.showError}
                      editPeriodsListInit={this.editPeriodsListInit}
                      periodsListInit={this.state.periodsListInit}
                      dowloadEmployeeReport={this.dowloadEmployeeReport}
                      actualizarEstadoYAlmacenar={this.actualizarEstadoYAlmacenar}
                      volverAlPasado={this.volverAlPasado}
                      regresoAlFuturo={this.regresoAlFuturo}
                      ref={this.childRefEmployee}
                      ref2={this.childRefHandle}
                      ref3={this.childRefDisplayAct}
                      childHistory={this.state.childHistory}
                      childFuture={this.state.childFuture}
                      stateHistory={this.state.stateHistory}
                      stateFuture={this.state.stateFuture}
                      hideTrip={this.hideTrip}
                    />
                  </div>
                </div>
              </div>
            
          } else if (this.state.somethingSearched === 1){// && this.state.generalView.length>0){
              tripContent = 
                <div id="content" className="mt-3">
                  <div className="card mb-4" >
                    <div className="card-body wid-100%">
                      <GeneralView
                        generalView={this.state.generalView}
                        showModalActivity={this.showModalActivity}
                        showSendSmsActivity={this.showSendSmsActivity}
                        showGraphic={this.showGraphic}
                        putFilterAlerts={this.putFilterAlerts}
                        sendMessage={this.sendMessage}
                        editActivityListInit={this.editActivityListInit}
                        employeesList={this.state.employeesList}
                        strStatus={this.state.strStatus}
                        usersEmployeeCount={this.usersEmployeeCount}
                        seslectDisplayMode={this.seslectDisplayMode}
                        displayType={this.state.displayType}
                        editEmployeesSelected={this.editEmployeesSelected}
                        employeesSelectedToShow={this.state.employeesSelectedToShow}
                        employeesSelected={this.state.employeesSelected}
                        showError={this.showError}
                        device={this.state.device}
                        search={this.search}
                        showCreationFields={this.showCreationFields}
                        myEmployee = {this.state.myEmployee}
                        start = {this.state.start}
                        end= {this.state.end}
                        actualizarEstadoYAlmacenar={this.actualizarEstadoYAlmacenar}
                        volverAlPasado={this.volverAlPasado}
                        regresoAlFuturo={this.regresoAlFuturo}
                        somethingSearched={this.props.somethingSearched}
                        ref={this.childRefGeneral}
                      />
                    </div>

                  </div>
                </div>
             
          } else if (this.state.somethingSearched === 3 || (Object.keys(this.state.myEmployee).length === 0 && this.state.showTripsTotal)){
            // && this.state.generalView.length>0){
            let porFecha = <div className="d-flex flex-row al-it-lft">
                
            <div className="card shadow w-100 m-2 p-15-px d-flex flex-row align-items-center">
                

                    <div className="d-flex w-70 h-70 flex-row">
                    <div className="w-50">
                        <p className="ml-5-px h-30">Desde</p>
                        <DatePicker
                            className="form-control form-control-lg h-70"
                            selected={this.state.dateDesde}
                            dateFormat="dd/MM/yyyy"
                            onChange={this.handleChangeDesde.bind(this)}
                            required
                        />
                    </div>

                    <div  className="w-50">
                        <p className="ml-5-px h-30">Hasta</p>
                        <DatePicker
                            className="form-control form-control-lg h-70"
                            selected={this.state.dateHasta}
                            dateFormat="dd/MM/yyyy"
                            onChange={this.handleChangeHasta.bind(this)}
                            required
                        />
                    </div>
                </div>
  
                <div className="w-30 h-70">
                        <p className="ml-5-px h-30"> </p>
                <div className="btn btn-primary w-100 h-70 p-5-px "
                    onClick={(event) => {
                      event.preventDefault();
                                      
                      this.tripsAndClients(this.state.showTrip, true, [])
                      this.actualizarEstadoYAlmacenar()
                
                    }}>
                    Buscar
                </div>
                </div>

            </div>
              
            
            
        </div>
            
              if (this.state.showTripsTotal){

                
                tripContent = 
                <div id="content" className="mt-3">
                 
                  <div className="card mb-4" >
                  {porFecha}
                  

                    <div className="card-body wid-100">
                    Emisiones de CO2 Clientes {this.state.absoluteCO2} kg <br></br>
                    Emisiones de CO2 Viajes {this.state.absoluteCO2Trips} kg
                      <TripsListTotal
                        absoluteCO2={this.state.absoluteCO2}
                        showEditTripFields={this.showEditTripFields}
                        tripsListInit = {this.state.tripsListInit}
                        editTripsListInit={this.editTripsListInit}
                        tripsTotal={this.state.tripsTotal}
                        clientsTotal={this.state.clientsTotal}
                        actualizarEstadoYAlmacenar={this.actualizarEstadoYAlmacenar}
                        hideTrip={this.hideTrip}
                        showCreateTripFields={this.showCreateTripFields}
                      />
                    </div>

                  </div>
                </div>
              } else {
                tripContent = 
                <div id="content" className="mt-3">
                  
                  <div className="card mb-4" >
                  {porFecha}
                  
                    <div className="card-body wid-100">
                    Emisiones de CO2 {this.state.absoluteCO2} kg <br></br>
                    Emisiones de CO2 Viajes {this.state.absoluteCO2Trip} kg
                      <ClientsListTotal
                        absoluteCO2 = {this.state.absoluteCO2}
                        clientsListInit = {this.state.clientsListInit}
                        editClientsListInit={this.editClientsListInit}
                        clientsTotal={this.state.clientsTotal}
                        actualizarEstadoYAlmacenar={this.actualizarEstadoYAlmacenar}
                        editClient={this.editClient}
                        tripsAndClients={this.tripsAndClients}


                      />
                    </div>

                  </div>
                </div>

              }
              
             
          } else {
            tripContent = <div></div>;
          }
        }
      }
        if (this.state.userRole == "employee" && this.state.showCreateTripFields) {
          listContent = (
            <div>
              <CreationCMR
                myTrip={this.state.myTrip}
                device={this.state.device}
                thisTripID={this.state.thisTripID}
                createTrip={this.createTrip}
                showError={this.showError}
                somethingSearched={this.state.somethingSearched}
                wantToCreate={this.state.wantToCreate}
                numberTrip={this.state.numberTrip}
                noWallet={this.state.noWallet}
                endPoint={this.state.endPoint}
                createStripeAccount={this.createStripeAccount}
                askCheckout={this.state.askCheckout}
              />
              {/* <TripCreation
                myEmployee={this.state.myEmployee}
                device={this.state.device}
                createTrip={this.createTrip}
                showError={this.showError}
              /> */}
            </div>
          );
        } else if (this.state.userRole === "employee" && this.state.showHolidays) {
          listContent = (
            <div>
              <HolidayEmployee
                device={this.state.device}
                showError={this.showError}
                endPoint={this.state.endPoint}
                userToken={this.state.userToken}
                myEmployee={this.state.myEmployee}
              />
            </div>
          );
        }
        if (this.state.selectedMode === '' && this.state.userToken === "null"){       
          table = <div></div>
        }else if (this.state.showANDirecto){
          table = 
            <div className='wid-15 te-ali-cen' >
            <br/><br/>
              <table className='wid-100 te-ali-cen'>
                <tr className='wid-100 te-ali-cen' >
                  <td className='wid-15 te-ali-cen'>
                  </td>
                  <td className='wid-70 te-ali-cen'>
                  </td>
                  <td className='wid-15 te-ali-cen'>
                  </td>

                </tr>
              </table>
              <ANDirecto endPoint={this.state.endPoint} device={this.state.device} showError={this.showError} token={this.state.userToken} />
            </div>
         
        } else if (this.state.device === "desktop" && (this.state.userRole === "admin" || this.state.userRole === "manager")) {
          table = 
            <div>
              <table className='wid-100'>
                <thead>
                  <tr>
                    <td></td>
                    <td></td>
                    <br />
                    <br />
                    <br />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='ver-ali-top wid-33'>
                      <div class="col" className='ver-ali-top wid-100' >
                        <div id="content" className="mt-3 ver-ali-top wid-100">
                          <div className="card mb-4 ver-ali-top wid-100">
                            <div className="card-body wid-100">

                              {actions}
                              {listContent}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                      <td className='ver-ali-top wid-66'>
                        <div class="col">
                          {tripContent}
                          {showUpload}
                        </div>
                      </td>

                  </tr>
                </tbody>
              </table>
            </div>
        }else if (this.state.userRole === 'employee' && this.state.somethingSearched === 2 && Object.keys(this.state.myEmployee).length > 0){
          table = 
            <div className='wid-100'>
            <br/><br/><br/>
              <table className='wid-100'>
                <tbody className='wid-100'>
                  <tr>
                    <td className='ver-ali-top wid-5'>
                    </td>
                    <td className='ver-ali-top wid-90'>
                      <div id="content" className="mt-3 ver-ali-top wid-100">

                        <div id="content" className="mt-3">
                          {listContent}
                        </div>
                      </div>
                    </td>
                    <td className='ver-ali-top wid-5'>
                    </td>
                  </tr>
                  <tr className='wid-100'>
                    <td className='ver-ali-top wid-5'>
                    </td>
                    <td className='ver-ali-top wid-90'>
                      <div class="col" className='ver-ali-top wid-100'>
                        <div id="content" className="mt-3 ver-ali-top wid-100" >
                          <div id="content" className="mt-3">
                            <div className="card mb-4" >
                              <div className="card-body wid-100%" >

                                <EmployeeDetails
                                  deleteTrip={this.deleteTrip}
                                  filterAlerts={this.state.filterAlerts}
                                  putFilterAlerts={this.putFilterAlerts}
                                  filterDiets={this.state.filterDiets}
                                  selectFilterAlerts={this.selectFilterAlerts}
                                  putFilterDiets={this.putFilterDiets}
                                  filtrarDietas={this.filtrarDietas}
                                  limpiarDietas={this.limpiarDietas}
                                  deshacerFiltrado={this.deshacerFiltrado}
                                  filteredActivities={this.state.filteredActivities}
                                  sendMessage={this.sendMessage}
                                  showModalPeriod={this.showModalPeriod}
                                  showEditTripFields={this.showEditTripFields}
                                  myEmployee={this.state.myEmployee}
                                  strStatus={this.state.strStatus}
                                  showModalActivity={this.showModalActivity}
                                  showSendSmsActivity={this.showSendSmsActivity}
                                  showGraphic={this.showGraphic}
                                  thisDeposited={this.state.thisDeposited}
                                  currentUser={this.state.currentUser}
                                  dowloadActivityReport={this.dowloadActivityReport}
                                  search={this.search}
                                  seslectDisplayMode={this.seslectDisplayMode}
                                  displayType={this.state.displayType}
                                  activityListInit={this.state.activityListInit}
                                  editActivityListInit={this.editActivityListInit}
                                  showCreationFields={this.showCreationFields}
                                  editTripsListInit={this.editTripsListInit}
                                  tripsListInit={this.state.tripsListInit}
                                  device={this.state.device}
                                  showCreateTripFields={this.showCreateTripFields}
                                  showHolidays={this.showHolidays}
                                  showError={this.showError}
                                  editPeriodsListInit={this.editPeriodsListInit}
                                  periodsListInit={this.state.periodsListInit}
                                  userRole={this.state.userRole}
                                  dowloadEmployeeReport={this.dowloadEmployeeReport}
                                  ref={this.childRefEmployee}
                                  ref2={this.childRefHandle}
                                  ref3={this.childRefDisplayAct}
                                  childHistory={this.state.childHistory}
                                  childFuture={this.state.childFuture}
                                  stateHistory={this.state.stateHistory}
                                  stateFuture={this.state.stateFuture}
                                  actualizarEstadoYAlmacenar={this.actualizarEstadoYAlmacenar}
                                />
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className='ver-ali-top wid-5'>
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>
        }else if (this.state.userRole === 'admin' || this.state.userRole === 'manager') {
          
          table = 
            <table className='wid-100'>
              <tbody>
                <tr className='wid-100'>
                  <th scope="col" className="text-center wid-100 he-20">                    
                  </th>
                </tr>
                <tr className='wid-100' >
                <br/><br/>

                  {actions}
                </tr>
                <tr className='wid-100'>
                  {listContent}
                </tr>
                <tr className='wid-100'>
                  {tripContent}
                </tr>
                <tr className='wid-100'>
                  {showUpload}
                </tr>
              </tbody>
            </table>
        
        } 
    

      if (this.state.device === "desktop") {
        if (window.innerHeight > 1200) {
          footerStyle = { position: "fixed", bottom: 0, left: 0, right: 0 };
        }
      }

      if (pageFullyLoaded && !this.state.loading) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

          if (this.state.device==="desktop"){
            footer = 
            <div classname="wid-100">
              <Footer 
                userToken = {this.state.userToken}
                style={footerStyle}
              />

              </div>
            
          } else {
            footer = (
              <div>
                <Footer userToken={this.state.userToken} style={footerStyle} />
              </div>
            );
          }
        } else {
          footer = <div></div>;
        }
      }

      if (employeesNow.length <= 3 && !(this.state.userToken === "null" || this.state.userToken === null || this.state.userToken === undefined)) {
        spacer = (
          <div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        );
      }
      if (this.state.showModalActivity === true) {
        return (
          <div>
            {navbar}

            <ModalActivity
              onClose={this.onClose}
              show={this.state.showModalActivity}
              showError={this.showError}
              selectedActivity={this.state.selectedActivity}
              defaultComments={this.state.defaultComments}
              defaultPlace={this.state.defaultPlace}
              defaultIdentifier={this.state.defaultIdentifier}
              filteredActivitieForEdit={this.state.filteredActivitieForEdit}
              editActivityDetails={this.editActivityDetails}
            >
              Añade los detalles
            </ModalActivity>
          </div>
        );

      } else if (this.state.showSendSmsActivity) {
        return (
          <div style={{ Zindex: "9998" }}>
            {navbar}
            <SendSmsActivity
              onClose={this.onClose}
              show={this.state.showSendSmsActivity}
              showError={this.showError}
              selectedActivity={this.state.selectedActivity}
              defaultComments={this.state.defaultComments}
              defaultPlace={this.state.defaultPlace}
              defaultIdentifier={this.state.defaultIdentifier}
              sendMessage={this.sendMessage}
              myEmployee={this.state.myEmployee}
              start={this.state.start}
              end={this.state.end}
              selectedActivityDict={this.state.selectedActivityDict}
            >
              Añade los detalles
            </SendSmsActivity>
          </div>
        );
      } else if (this.state.showGraphic === true) {
        return (
          <div>
            {navbar}
            <br></br><br></br><br></br><br></br><br></br>
            <EmployeeGraphic
              onClose={this.onClose}
              show={this.state.showGraphic}
              showError={this.showError}
              loading={this.state.loading}
              putLoading={this.putLoading}
              endPoint={this.state.endPoint}
              userToken={this.state.userToken}
              dataGraphic={this.state.dataGraphic}
              showGraphic={this.showGraphic}
              employees={this.state.employees}
              device={this.state.device}
              chartType={this.state.chartType}
            >
              Añade los detalles
            </EmployeeGraphic>
          </div>
        );
      } else if (this.state.showLastModal === true) {
        return (
          <div>
            {navbar}
            <ModalLasts
              showError={this.showError}
              onClose={this.onClose}
              show={this.state.showLastModal}
              generalView={this.state.generalView}
              employeesList={this.state.employeesList}
            >
              Los datos están incompletos
            </ModalLasts>
          </div>
        );
      }else if(this.state.askTacProvider === true){
        
        return (
          <div className='wid-100'>
            {navbar}
            
          </div>
        );
      }else if(this.state.showPlaceholderOthers === true){
        return (
          <div className='wid-100'>
            {navbar}
            <table className='wid-100 he-100'>
              <tr className='wid-100 he-20'>
                <td className='wid-30'>
                </td>
                <td className='wid-40'>
                </td>
                <td className='wid-30'>
                <br/><br/><br/><br/><br/><br/><br/><br/>

                </td>
              </tr>
              <tr className='wid-100 he-60'>
                <td>
                  <div className="card mb-4" >
                    <div className="card-body wid-100" >
                      <p style={{ color: "#5A5A5A", fontWeight: "light" }}> No te preocupes, podemos integrar cualquier sistema de Tacógrafos</p>
                      <p style={{ color: "#5A5A5A", fontWeight: "light" }}> Para conseguirlo, contáctanos en <a href="https://traxain.com"> Traxain.com</a>, escríbenos a <a href="mailto:ignacio@traxain.com"> ignacio@traxain.com </a>, o llama al  <a href="tel:+34638269633">+34 638 26 96 33 </a>, y conectaremos tus sistemas directamente</p>
                      <p style={{ color: "#5A5A5A", fontWeight: "light" }}> También puedes subir directamente los archivos</p>
                      <table className='wid-100%'>
                        <tr className='wid-100'>
                          <td className='wid-45'>
                            <button type="button" className="btn btn-primary btn-block btn-lg wid-100 fs-16" style={{backgroundColor: "green", border: "none"}} onClick={(event) => {
                              this.setState({ showPlaceholderOthers: false })
                              this.setState({ upLoadTGDManually: true })
                            }}>
                              Subir manualmente
                            </button>
                          </td>
                          <td className='wid-10' >
                          </td>
                          <td className='wid-45' >
                            <button type="button" className="btn btn-primary btn-block btn-lg wid-100 fs-16" style={{backgroundColor: "red", border: "none",}} onClick={(event) => {
                              this.setState({ showPlaceholderOthers: false })
                              this.setState({ errorHappened: true })
                              this.setState({ errorMsg: 'Aún no tenemos datos para ti. Contáctanos en traxain.com, escribiendo a ignacio@traxain o llamando al +34 638 26 96 33 (4)' })
                            }}>

                              Cerrar
                            </button>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </td>
              </tr>
              <tr className='wid-100 he-20'>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </table>
          </div>
        );
      } else if (this.state.askContCred === true) {
        return (
          <div className='wid-100'>
            {navbar}
            <ModalContinental 
              showError = {this.showError}

              onClose={this.onClose}
              show={this.state.askContCred}
              editContinentalCredentials={this.editContinentalCredentials}
              editRole={this.editRole}
              editTacProvider={this.editTacProvider}
            >
              Introduce tus credenciales de Continental
            </ModalContinental>
          </div>
        );
      } else if (this.state.newPassAsked === true) {
        return (
          <div className='wid-80'>
            {navbar}
            <ModalChangePass 
              showError = {this.showError}

              onClose={this.onClose}
              show={this.state.newPassAsked}
              askNewPass={this.askNewPass}
              assignNewPass={this.assignNewPass}
              getOldPass={this.getOldPass}
              device={this.state.device}
            >
              Introduce tu nueva contraseña
            </ModalChangePass>
          </div>
        );
      } else if (this.state.showNavigatorModal === true) {
        return (
          <div>
            {navbar}
            <NavigatorModal
              showError={this.showError}
              account={this.state.account}
              device={this.state.device}
              product={this.state.product}
              selectProduct={this.selectProduct}
              showCreationFields={this.showCreationFields}
              usersEmployeeCount={this.usersEmployeeCount}
              usersEmployeeLoad={this.usersEmployeeLoad}
              userRole={this.state.userRole}
              askNewPass={this.askNewPass}
              selectANDirecto={this.selectANDirecto}
              showANDirecto={this.state.showANDirecto}
              showNavigatorModal={this.showNavigatorModal}
              showNavigatorModalHome={this.showNavigatorModalHome}
              show={this.state.showNavigatorModal}
              onCloseNav={this.onCloseNav}
              onClose={this.onClose}
            />
          </div>
        );
      } else if (this.state.showLoginModalVar === true) {
        return (
          <div>
            {navbar}
            <LoginModal
              putLoading={this.putLoading}
              showError={this.showError}
              account={this.state.account}
              device={this.state.device}
              product={this.state.product}
              selectProduct={this.selectProduct}
              showCreationFields={this.showCreationFields}
              usersEmployeeCount={this.usersEmployeeCount}
              usersEmployeeLoad={this.usersEmployeeLoad}
              userRole={this.state.userRole}
              askNewPass={this.askNewPass}
              selectANDirecto={this.selectANDirecto}
              showANDirecto={this.state.showANDirecto}
              showLoginModal={this.showLoginModal}
              show={this.state.showLoginModalVar}
              onCloseLog={this.onCloseLog}
            />
          </div>
        );
      } else if (this.state.showRegisterModalVar === true) {
        return (
          <div>
            {navbar}
            <RegisterModal
              putLoading={this.putLoading}
              showError={this.showError}
              account={this.state.account}
              device={this.state.device}
              product={this.state.product}
              selectProduct={this.selectProduct}
              showCreationFields={this.showCreationFields}
              usersEmployeeCount={this.usersEmployeeCount}
              usersEmployeeLoad={this.usersEmployeeLoad}
              userRole={this.state.userRole}
              askNewPass={this.askNewPass}
              selectANDirecto={this.selectANDirecto}
              showANDirecto={this.state.showANDirecto}
              showRegisterModal={this.showRegisterModal}
              show={this.state.showRegisterModalVar}
              onCloseReg={this.onCloseReg}
            />
          </div>
        );
      } else if (this.state.showPeriodModal === true) {
        return (
          <div>
            {navbar}
            <ModalPeriod

              show={this.state.showPeriodModal}
              editPeriodDetails={this.editPeriodDetails}
              selectedPeriod={this.state.selectedPeriod}
              onClose={this.onClose}
            >
              Edita la dieta
            </ModalPeriod>
          </div>
        );
      } else if (this.state.showTripModal === true) {
        //Seleccionar el trip de myemployee.trips con id = this.state.selectedTrip
        let selectedTripObject
        try{
          selectedTripObject = this.state.myEmployee.trips.filter((trip) => trip.id === this.state.selectedTrip);
        
        }catch{
          selectedTripObject = this.state.tripsTotal.filter((trip) => trip.id === this.state.selectedTrip);
        
        }
        
        
        return (
          <div>
            {navbar}
            {error}
            <ModalCMR
              myTrip={selectedTripObject[0]}
              myUser={this.state.myUser}
              userRole={this.state.userRole}
              strStatus={this.state.strStatus}
              noWallet={this.state.noWallet}
              currentUser={this.state.currentUser}
              errorHappened={this.state.errorHappened}
              errorMsg={this.state.errorMsg}
              userToken={this.state.userToken}
              endPoint={this.state.endPoint}
              search={this.search}
              editCMR={this.editCMR}
              showError={this.showError}
              onClose={this.onClose}
              showEditTripFields={this.showEditTripFields}
              myEmployee={this.state.myEmployee}
              device={this.state.device}
              showTripModal={this.state.showTripModal}
              loading={this.state.loading}
              // Props de Navbar
              account={this.state.account}
              product={this.state.product}
              selectProduct={this.selectProduct}
              showCreationFields={this.state.showCreationFields}
              usersEmployeeCount={this.usersEmployeeCount}
              usersEmployeeLoad={this.usersEmployeeLoad}
              askNewPass={this.askNewPass}
              selectANDirecto={this.selectANDirecto}
              showANDirecto={this.state.showANDirecto}
              showNavigatorModal={this.showNavigatorModal}
              showLoginModal={this.showLoginModal}
              showRegisterModal={this.showRegisterModal}
              winWidth={this.state.winWidth}
              winHeight={this.state.winHeight}
              volverAlPasado={this.props.volverAlPasado}
              regresoAlFuturo={this.props.regresoAlFuturo}
              stateHistory={this.state.stateHistory}
              stateFuture={this.state.stateFuture}
            />
            {/* <ModalTrip
              show={this.state.showTripModal}
              originTrip={this.state.originTrip}
              destinationTrip={this.state.destinationTrip}
              dateTrip={this.state.dateTrip}
              modeTrip={this.state.modeTrip}
              editTripDetails={this.editTripDetails}
              selectedTrip={this.state.selectedTrip}
              onClose={this.onClose}
            >
              Edita el viaje
            </ModalTrip> */}
          </div>
        );
      } else {
        let winWidth = this.state.winWidth;
        let askIntall;

        if (this.state.device === "desktop") {
          askIntall = <div></div>;
        } else {
          askIntall = <div></div>;
        }

        return (
          <div className='' style={{ width: winWidth, height: window.innerHeight > 1200 ? window.innerHeight : "auto" }}>
           {/* < PushNotification /> */}

           {navbar}

            

            <div className='' >              
                {error}
                {loader}
                {content}              
                {table}
                {askIntall}
                {modalamployeealpha}

            </div>
            <br/><br/><br/><br/><br/><br/><br/>
              {footer}

          </div>
        );
      }


//<<<<<<< HEAD
  //   }catch (err){
  //     this.setState({ errorHappened: true })
  //     this.setState({ errorMsg: 'Se ha producido un error no especificado, esta ventana se va a recargar' })
  //     //setTimeout(() => window.location.reload(), 2000)
  //  }
//=======
    }catch (err){

      
      this.setState({ errorHappened: true })
      this.setState({ errorMsg: 'Se ha producido un error, esta ventana se va a recargar' })
      console.log(err)
      if (Math.random()>0.5){
        setTimeout(() => {
          window.location.href = 'https://drive-team.es';
        }, 2000)
      }else{
        setTimeout(() => {
          window.location.href = 'https://www.drive-team.es';
        }, 2000)

      }
 
  }

  }


  seleccionBoton = () => {
    this.setState({ isFeatureEnabled: false });
  };

  seleccionBotonAlpha = () => {
    this.setState({ isFeatureEnabled: true });
  };
// }catch (err){
//       this.setState({ errorHappened: true })
//       this.setState({ errorMsg: 'Se ha producido un error no especificado, esta ventana se va a recargar' })
//       setTimeout(() => window.location.reload(), 2000)
//    }

}

export default Home;
