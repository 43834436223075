import React from "react";


const RoundEnumeratedList = ({ items }) => {
  return (
    <div className="mt-15 text-intend-0p">
      <ul className="list-unstyled pl-30">
      {items.map((item, index) => (
        <li key={index} className="mb-10">
          &bull;{" "}
          <span>{item}</span>
        </li>
      ))}
      </ul>
    </div>
    
  );
};

export default RoundEnumeratedList;
