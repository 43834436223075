import React, { useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import './App.css';
import logo from '../logo.webp'
import logoPNG from '../logonuevo.png'
import { Link, NavLink } from 'react-router-dom'
import LoginModal from './LoginModal';
import { left } from '@popperjs/core';
import Logout from './Logout';
import Dropdown from 'react-dropdown';

export function ServiceSelector() {
  var device ="mobile"
  let ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    device = "tablet";
  }
  else if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    device =  "mobile";
  }

  else{
    device =  "desktop";
  } 
    // console.log("ServiceSelector");
    const navigate = useNavigate();
  var style = 'no-border  fs-7'
  var styleContainer = "mtl-0 ptl-0"
  var styleOption = "mtl-0 ptl-0"
  // var styleContainer = "react-dropdown-container"
  // var styleOption = "react-dropdown-option"

  if (device === "desktop") {
    // console.log("desktop");
    style = 'w-100 bn  btn-block nav-btn fs-5  tx-al-mid h-100'
    styleContainer = "react-dropdown-container w-100 tx-al-mid"
    styleOption = "react-dropdown-option w-100 tx-al-mid"
    
  styleOption = "react-dropdown-option"
// }else{
//   console.log("mobile");
}

    const establishService = (service) => {

      if (service["value"] === "works") {
        navigate('/blog/drive-team-tgd')
    }else{
      navigate('/blog/calculo-emisiones-co2-transporte-camiones')
    }
  }



  return (
      



    //   const dropdown = 
      <div className={style}>
      <Dropdown
      // take out the border
      className="no-border"
      controlClassName={styleContainer}
      menuClassName={styleOption}
      onChange={ establishService}
      placeholder={"Servicios"}
      
      options ={[
        { value: "works", label: "Laborales" },
        { value: "co2", label: "Emisiones" }//,
        // {value: "general",label: "Sobre Drive-Team"}

      ]}
    />
    </div>
  )
                  

                  
    //    return (
    //        {dropdown}
    //    )
    // }
}

    

export default ServiceSelector;
